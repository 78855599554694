import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
// import iconA from "assets/img/iconA.svg"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import onesazlogo from "assets/img/onesaz.png";
import timeLeft from "assets/img/time-left.svg";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import paper_1_2k10 from "schemas/2010P1";
import paper_2_2k10 from "schemas/2010P2";
import paper_1_2k11 from "schemas/2011P1";
import paper_2_2k11 from "schemas/2011P2";
import paper_1_2k12 from "schemas/2012P1";
import paper_2_2k12 from "schemas/2012P2";
import paper_1_2k13 from "schemas/2013P1";
import paper_2_2k13 from "schemas/2013P2";
import paper_1_2k14 from "schemas/2014P1";
import paper_2_2k14 from "schemas/2014P2";
import paper_1_2k15 from "schemas/2015P1";
import paper_2_2k15 from "schemas/2015P2";
import paper_1_2k16 from "schemas/2016P1";
import paper_2_2k16 from "schemas/2016P2";
import paper_1_2k17 from "schemas/2017P1";
import paper_2_2k17 from "schemas/2017P2";
import paper_1_2k18 from "schemas/2018P1";
import paper_2_2k18 from "schemas/2018P2";
import paper_1_2k19 from "schemas/2019P1";
import paper_2_2k19 from "schemas/2019P2";
import paper_1_2k20 from "schemas/2020P1";
import paper_2_2k20 from "schemas/2020P2";
import paper_1_2k21 from "schemas/2021P1";
import paper_2_2k21 from "schemas/2021P2";
import paper_1_2k22 from "schemas/2022P1";
import paper_2_2k22 from "schemas/2022P2";
import paper_1_2k23 from "schemas/2023P1";
import paper_2_2k23 from "schemas/2023P2";
import paper_1_2k24 from "schemas/2024P1";
import paper_2_2k24 from "schemas/2024P2";

import analyticsAxios from "../apis/analyticsAxios";
import examAxios from "../apis/examServerAxios";
import AdvanceNumbersPanel from "./AdvanceNumbersPanel";
// import Box from '@material-ui/core/Box';
import BCONM_1 from "../schemas/BCONM1";
import { preloadImages } from "../utils/cacheImageURLs";
import AdvanceSubjects from "./AdvanceSubjects";
import AlertComponent from "./AlertComponent";
import CacheImg from "./CacheImg";
import CustomInstructions from "./CustomInstructions";
import ExamSummaryAdv from "./ExamSummaryAdv";
import "./HomePanel.css";
import CheckedOptions from "./IconA";
import InputIntegerPanel from "./InputIntegerPanel";
import InstructionDialog from "./InstructionDialog";
import MatrixInput from "./MatrixInput";
import NetworkProgress from "./NetworkProgress";
import QuestionPaperDialog from "./QuestionPaperDialog";
import SectionInstructions from "./SectionInstructions";
import store from "store";
import TrueFalseInput from "./TrueFalseInput";
import { Tooltip } from "@material-ui/core";
// import Question from './Question';
var analyticsConstantData = require("./nta-constants").analyticsConstantData;
// var examServerUrl = require('./nta-constants').examServerUrl;
const submitDisabledDuration = {
  "sr.onesaz.com": 900,
  "sreducation.onesaz.com": 900,
  "bhashyam.onesaz.com": 300,
  "onesaz.com": 300,
  "localhost:4000": 300,
  "valleyoak.onesaz.com": 300,
  "sriabhidakp.onesaz.com": 300,
  "miity.onesaz.com": 300,
  "sriabhida.onesaz.com": 300,
  "sanskriti.onesaz.com": 300,
  "agastya.onesaz.com": 300,
  "tirumala.onesaz.com": 300,
  "pinegrove.onesaz.com": 300,
  "neutrino.onesaz.com": 300,
  "vijna.onesaz.com": 300,
  "ayati.onesaz.com": 300,
  "sundar.onesaz.com": 300,
  "metamind.onesaz.com": 300,
  "motion.onesaz.com": 4200,
  "excellencia.onesaz.com": 300,
  "arastu.onesaz.com": 300,
  "kle.onesaz.com": 300,
  "cognizant.onesaz.com": 300,
  "ligadepatil.onesaz.com": 300,
};
var schemas = {
  BCONM_1: BCONM_1,
  "2k24_paper_2": paper_2_2k24,
  "2k24_paper_1": paper_1_2k24,
  "2k23_paper_2": paper_2_2k23,
  "2k23_paper_1": paper_1_2k23,
  "2k22_paper_2": paper_2_2k22,
  "2k22_paper_1": paper_1_2k22,
  "2k21_paper_2": paper_2_2k21,
  "2k21_paper_1": paper_1_2k21,
  "2k20_paper_2": paper_2_2k20,
  "2k20_paper_1": paper_1_2k20,
  "2k19_paper_1": paper_1_2k19,
  "2k19_paper_2": paper_2_2k19,
  "2k18_paper_1": paper_1_2k18,
  "2k18_paper_2": paper_2_2k18,
  "2k17_paper_1": paper_1_2k17,
  "2k17_paper_2": paper_2_2k17,
  "2k16_paper_1": paper_1_2k16,
  "2k16_paper_2": paper_2_2k16,
  "2k15_paper_1": paper_1_2k15,
  "2k15_paper_2": paper_2_2k15,
  "2k14_paper_1": paper_1_2k14,
  "2k14_paper_2": paper_2_2k14,
  "2k13_paper_1": paper_1_2k13,
  "2k13_paper_2": paper_2_2k13,
  "2k12_paper_1": paper_1_2k12,
  "2k12_paper_2": paper_2_2k12,
  "2k11_paper_1": paper_1_2k11,
  "2k11_paper_2": paper_2_2k11,
  "2k10_paper_1": paper_1_2k10,
  "2k10_paper_2": paper_2_2k10,
};
var moment = require("moment");

const styles = (theme) => ({
  instructionsHeading: {
    color: "#FFDD00",
  },
  instructionsBanner: {
    height: "50px",
    backgroundColor: "#494B5E",
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
  },
  dense: {
    marginTop: theme.spacing.unit * 2,
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
  },
  checkedRadioOptions: {
    width: "40px",
    height: "40px",
  },
  radioOptions: {
    width: "40px",
    height: "40px",
    backgroundColor: "#e94733", //'#eb5944' ,//'#64cc82', //'#72e192', //'#2aabe2',
    color: "white",
  },
  radio: {
    padding: "4px",
    color: "black",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    //  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    "&$checked": {
      // background: 'linear-gradient(60deg,#5db85c, #5db85c)',
      background: "linear-gradient(45deg, #5db85c 30%, #5db85c 90%)",
      color: "#fff",
    },
  },
  checked: {
    backgroundColor: "linear-gradient(60deg,#5db85c, #5db85c)",
    color: "#5db85c", //'#535d8c'
  },
  multipleradio: {
    padding: "4px",
    color: "black",
  },
  multiplechecked: {
    // backgroundColor: 'linear-gradient(60deg,#5db85c, #5db85c)',
    color: "#5db85c !important", //'#535d8c'
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  expired: {
    background: "red",
    borderRadius: "25px",
    color: "white",
    fontWeight: "600",
  },
  btnAttempt: {
    //   maxWidth: 'max-content',
    // marginLeft: '15%',
    minwidth: "90%",
  },
  avatar: {
    margin: 10,
  },
  group: {
    margin: theme.spacing.unit * 1,
  },
  labelMargin: {
    marginBottom: theme.spacing.unit * 1,
  },
  btndefault: {
    border: "1px solid grey",
    color: "#333",
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  btnreview: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
  },
  btnwarning: {
    color: "#fff",
    backgroundColor: "#f0ad4e",
    borderColor: "#eea236",
  },
  btnSubmit: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #0B80F1 30%,#0B80F1 90%)",
    borderColor: "#4cae4c",
  },
  btnsuccess: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #5db85c 30%,#5db85c 90%)",
    borderColor: "#4cae4c",
  },
  btnsuccess1: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #178DFF 30%,#178DFF 90%)",
    borderColor: "#4cae4c",
  },
  // btnSubmit: {
  // background: 'green',
  // color: 'white'
  //     },
  button: {
    margin: theme.spacing.unit * 1,
  },
  input: {
    display: "none",
  },
  bigAvatar: {
    marginTop: -10,
  },
  root: {
    flexGrow: 1,
  },
  profile: {
    flexGrow: 4,
  },
  subjectBar: {
    // height: theme.spacing(8)
  },
  paper: {
    padding: theme.spacing.unit * 4,
    textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  gridsep: {
    minHeight: theme.spacing.unit * 3,
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 1,
  },
  mathDisplay: {
    display: "none",
  },
  textWrapper: {
    whiteSpace: "normal",
  },
  mcqColor: {
    color: "#31456A",
  },
});
class Advance extends React.Component {
  timer;
  questionTypes = [
    "single",
    "partial",
    "multiple",
    "single_partial",
    "float",
    "integer",
    "matrix",
    "partial_p",
    "multiple_p",
    "float_p",
    "integer_p",
    "matrix_p",
  ];
  statusdisplay = {
    NOT_VISITED: 0,
    NOT_ANSWERED: 0,
    ANSWERED: 0,
    MARKED_FOR_REVIEW: 0,
    ANSWERED_MARKED_FOR_REVIEW: 0,
  };
  isSubmitted = false;
  a = 4;
  time_started;
  remainingTime = Date.now();
  displayTime = 0;
  expandfromleft = {
    left: "90%",
    display: "unset",
    xsLeft: 9,
    xsRight: 3,
    isExpanded: false,
    icon: <KeyboardArrowRightIcon />,
  };
  q = Array(90)
    .fill(0)
    .map((val, index) => index + 1);
  questions = [];
  studentAnswers = [];
  constructor(props) {
    super(props);
    this.state = {
      current_question: {},
      questions: [],
      confirmationtType: "SUMMARY",
      integerValue: "",
      confirmOpen: false,
      alertText: "",
      alertType: "",
      ifImagesLoading: true,
      imageURLs: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMultiple = this.handleMultiple.bind(this);
    this.isChecked = this.isChecked.bind(this);
    // this.handleInteger = this.handleInteger.bind(this);
    this.submit = this.submit.bind(this);
    this.setIntegerValue = this.setIntegerValue.bind(this);
  }
  handleCloseAlertBox = () => {
    this.setState({ confirmOpen: false, alertText: "" });
  };
  handleChange(event) {
    let update_c_q = this.state.current_question;
    update_c_q.markedAnswer = event.target.value;
    this.setState({ current_question: update_c_q });
    // analytics
    this.analyticsUPStream(
      update_c_q.question_number,
      "OPTION_SELECTION",
      update_c_q.status,
      update_c_q.markedAnswer,
      update_c_q.subject
    );
    // end of analytics
  }
  handleMultiple = (option) => (event) => {
    console.log(
      option,
      this.state.current_question.markedAnswer,
      event.target.checked
    );
    if (this.state.current_question.markedAnswer == "NA") {
      this.state.current_question.markedAnswer = "";
    }
    if (event.target.checked) {
      if (this.state.current_question.markedAnswer.includes(option)) {
      } else {
        this.state.current_question.markedAnswer += option;
        this.forceUpdate();
      }
    } else {
      if (this.state.current_question.markedAnswer.includes(option)) {
        this.state.current_question.markedAnswer = this.state.current_question.markedAnswer.replace(
          option,
          ""
        );
        this.forceUpdate();
      }
    }

    // this.state.current_question.markedAnswer += option;
  };
  isChecked = (option) => {
    if (this.state.current_question.markedAnswer == "NA") {
      return false;
    }
    return this.state.current_question.markedAnswer.includes(option);
  };

  /**
   * for clearing the option
   */
  clear() {
    const update_c_q = this.state.current_question;
    update_c_q.markedAnswer = "NA";
    update_c_q.status = "NOT_ANSWERED";
    let update_opts = {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        student_id: this.props.user,
        test_name: this.props.examName,
        question_number: update_c_q.question_number,
        question_state: update_c_q.status,
        status: update_c_q.status,
        question_answer: 1,
        markedAnswer: update_c_q.markedAnswer,
      }),
    };

    examAxios
      .post("/exams/updateanswer", update_opts.body)
      .catch((err) => {
        //handling
        console.log("err in clearing..", err);
      })
      .then((results) => results.data)
      .then((res) => {
        // console.log(res.status)
      });
    // Analytics
    this.analyticsUPStream(
      update_c_q.question_number,
      "CLEAR_SELECTION",
      "NOT_ANSWERED",
      update_c_q.subject
    );
    // end of analytics
    this.setState({ current_question: update_c_q });

    // update_c_q.markedAnswer= null;
  }
  async handlePendingAnswers() {
    store.each(async (val, key) => {
      if (!isNaN(key)) {
        await examAxios
          .post("/exams/updateanswer", val)
          .catch((err) => {
            // handle error
            // this.questions[index - 1].status = prev_status;
            // this.studentAnswers[index - 1].status = prev_status;
            // console.log('error in saving the markedanswer')
          })
          .then((results) => {
            if (results) return results.data;
          })
          .then((res) => {
            // console.log(res.status)
            store.remove(key);
          });
      }
    });
  }
  submit(val = "submit") {
    console.log(val);
    Object.keys(this.statusdisplay).forEach((key) => {
      this.statusdisplay[key] = 0;
    });
    this.questions.forEach((question) => {
      this.statusdisplay[question.status] += 1;
    });

    switch (val) {
      case "YES":
        let body = JSON.stringify({
          student_id: this.props.user,
          test_name: this.props.examName,
        });
        this.setState({ confirmationtType: "PENDING_ITEMS" });
        this.forceUpdate();
        this.handlePendingAnswers();
        examAxios.post("/exams/submittest", body).then((val) => {
          console.log(val);
          this.setState({ confirmationtType: "RESULT" });
          clearInterval(this.timer);
          this.remainingTime = -1;
          this.isSubmitted = true;
          this.forceUpdate();
          examAxios
            .post("/exams/results", body)
            .then((respResults) => {
              console.log(respResults);
            })
            .catch((err) => {
              console.log(err);
            });
        });
        break;
      case "NO":
        this.isSubmitted = false;
        break;
      case "submit":
        console.log(val);
        this.isSubmitted = true;
        break;
      case "VIEW_RESULT":
        console.log("view");
        const { history, examName, location, paper } = this.props;
        history.push({
          pathname: `/${examName}/new-result`,
          search: `?paper=${paper}&test=${location.state.testDisplayName}`,
        });

        this.forceUpdate();
        break;
    }
    this.forceUpdate();
  }
  attempt(status, index, noskip = true) {
    // console.log(this.state.current_question)
    if (
      (noskip && !this.state.current_question.markedAnswer) ||
      (this.state.current_question.markedAnswer == "NA" &&
        status !== "MARKED_FOR_REVIEW")
    ) {
      this.setState({
        confirmOpen: true,
        alertText: "Please choose an option",
      });
      return;
    }
    if (this.questions[index - 1].markedAnswer == "") {
      this.questions[index - 1].markedAnswer = "NA";
    }
    if (
      this.questions[index - 1].markedAnswer !== "NA" &&
      status == "MARKED_FOR_REVIEW"
    ) {
      status = "ANSWERED_MARKED_FOR_REVIEW";
    }
    let prev_status = this.questions[index - 1].status;
    if (index > this.questions.length) {
      this.forceUpdate();
      return;
    }
    console.log(this.questions[index - 1], index);
    let update_c_q = this.questions[index - 1];

    let update_opts = {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        student_id: this.props.user,
        test_name: this.props.examName,
        question_number: update_c_q.question_number,
        question_state: status,
        status: status,
        question_answer: 1,
        markedAnswer:
          update_c_q.markedAnswer == "" ? "NA" : update_c_q.markedAnswer,
      }),
    };
    // let  updateUrl = examServerUrl+ '/exams/updateanswer';
    store.set(this.questions[index - 1].question_number, update_opts.body);
    examAxios
      .post("/exams/updateanswer", update_opts.body)
      .then((results) => results.data)
      .then((res) => {
        if (res) {
          this.questions[index - 1].status = status;
          this.studentAnswers[index - 1].status = status;
        }
        // console.log(res.status)
        store.remove(this.questions[index - 1].question_number);
      })
      .catch((err) => {
        // handle error
        this.setState({
          confirmOpen: true,
          alertText: `Failed to save the answer of Question No: "${index}", please check your internet correction and try again.`,
        });
        this.questions[index - 1].status = prev_status;
        this.studentAnswers[index - 1].status = prev_status;
        this.forceUpdate();
        console.log("error in saving the markedanswer");
      });
    if (index >= this.questions.length) return;
    if (this.questions[index].status == "NOT_VISITED") {
      this.questions[index].status = "NOT_ANSWERED";
      this.studentAnswers[index].status = "NOT_ANSWERED";
    }

    //====== for analytics ====

    // this.analyticsUPStream(update_c_q.question_number, "EXIT", update_c_q.status, update_c_q.markedAnswer);
    // end of analytics
    // this.setState({ current_question: this.questions[index], questions: this.questions });
    this.forceUpdate();
    this.displayQuestion(index + 1);
    // this.analyticsUPStream(current_question.question_number, "DISPLAY", current_question.status, current_question.markedAnswer);
  }
  displayQuestion(index, status = "NOT_ANSWERED") {
    if (index < 1 || index > this.state.questions.length) {
      return;
    }
    let update_c_q = {};
    update_c_q = this.questions[index - 1];
    // console.log(this.questions[index - 1],index);
    if (this.questions[index - 1].status == "NOT_VISITED") {
      this.questions[index - 1].status = status;
      // this has to change only when it is not visited
      console.log(this.studentAnswers[index - 1], this.questions[index - 1]);
      this.studentAnswers[index - 1].status = status;
      //
      // updating the not_answered state
      //

      let update_opts = {
        method: "post",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          student_id: this.props.user,
          test_name: this.props.examName,
          question_number: update_c_q.question_number,
          question_state: update_c_q.status,
          status: update_c_q.status,
          question_answer: 1,
          markedAnswer: update_c_q.markedAnswer,
        }),
      };
      // let  updateUrl = examServerUrl+ '/exams/updateanswer';
      examAxios
        .post("/exams/updateanswer", update_opts.body)
        .catch((err) => {
          // handle error
          console.log("error in saving the not_answered state");
        })
        .then((results) => results.data)
        .then((res) => {
          // console.log(res.status)
        });

      //  end of update
    }
    if (this.state.current_question)
      this.analyticsUPStream(
        this.state.current_question.question_number,
        "EXIT",
        this.state.current_question.status,
        this.state.current_question.markedAnswer,
        this.state.current_question.subject
      );
    // analytics
    this.analyticsUPStream(
      update_c_q.question_number
        ? update_c_q.question_number
        : this.questions[index - 1].question_number,
      "DISPLAY",
      update_c_q.status,
      update_c_q.markedAnswer,
      update_c_q.subject
    );
    // end of analytics
    //    console.log(this.state.current_question)
    this.setState({ current_question: this.questions[index - 1] });
    //  console.log(this.state.current_question)
    this.forceUpdate();
  }
  componentDidMount() {
    const { history } = this.props;
    let queryData = {
      student_id: this.props.user,
      test_name: this.props.examName,
    };
    let url = "/exams/writetest";
    // url = '/2018p1.json';
    // const getData =  getQuestions(url,queryData);
    // console.log(getData);
    examAxios
      .post(url, queryData)
      .then((results) => results.data)
      .then((value) => {
        if (value.payload.test_status == "FINISHED") {
          history.push("/home");
          return;
        }
        let qNumPerSubject = {};
        this.questions = value.payload.Items.map((item, i) => {
          item.markedAnswer = "";
          if (!item.status) {
            item.status = "NOT_VISITED";
          }
          if (qNumPerSubject[item.subject] == undefined) {
            qNumPerSubject[item.subject] = 1;
          } else {
            qNumPerSubject[item.subject] += 1;
          }
          return { ...item, tempQNo: i + 1 };
        });
        if (value.payload.code !== undefined) {
          const examCode = "Code" + value.payload.code;
          if (value.payload[examCode] !== undefined) {
            const codeQuestionsOrder = value.payload[examCode].split(",");
            const rearrangeQuestions = [];
            codeQuestionsOrder.forEach((q_num) => {
              rearrangeQuestions.push(
                ...this.questions.filter((q) => q.question_number == q_num)
              );
            });
            this.questions = rearrangeQuestions.map((item, i) => {
              return { ...item, tempQNo: i + 1 };
            });
          }
        }
        console.log(qNumPerSubject, this.questions);
        // let examUrl = examServerUrl+"/exams/studentanswers";
        let opts = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            student_id: this.props.user,
            test_name: this.props.examName,
          }),
        };
        let val = JSON.stringify(opts);
        console.log(val);
        //  this.studentAnswers=[]
        examAxios
          .post("/exams/studentanswers", opts.body)
          .then((res) => res.data)
          .then((res) => {
            this.studentAnswers = res.payload.Items[0].answers;

            let reArrangeStudentAnswers = []; // this is as per question order;
            this.questions.forEach((q) => {
              reArrangeStudentAnswers.push(
                ...this.studentAnswers.filter(
                  (val) => val.question_number == q.question_number
                )
              );
            });
            if (this.questions.length > 0) {
              console.log(reArrangeStudentAnswers);
              this.studentAnswers = reArrangeStudentAnswers;
              this.studentAnswers = this.studentAnswers.map((answer, index) => {
                if (!answer.status) {
                  answer.status = "NOT_VISITED";
                }
                this.questions[index].status = answer.status;
                this.questions[index].markedAnswer =
                  answer.markedAnswer == undefined ? "NA" : answer.markedAnswer;
                console.log(this.questions[index].markedAnswer, "markedAnswer");
                // console.log(this.questions, this.studentAnswers)
                return answer;
              });
              // ############ pre load images to cache it ###############

              preloadImages(this.questions)
                .then((res) => {
                  this.setState({
                    ifImagesLoading: false,
                    imageURLs: res,
                  });
                  console.log(`${res.length} images pre-loaded`);
                })
                .catch((error) => {
                  this.setState({
                    ifImagesLoading: false,
                  });
                  console.error("caching error:798", error);
                });
              // ########################################################
              this.setState({
                current_question: this.questions[0],
                questions: this.questions,
                qNumPerSubject: qNumPerSubject,
              });
              this.displayQuestion(1);
            }
          })
          .catch((err) => {
            console.log(err);
          }); //end of student anwers
        //   this.setState({ current_question: this.questions[0], questions: this.questions, qNumPerSubject: qNumPerSubject });
        //     this.displayQuestion(1);
        let res = value;
        console.log(res);
        //  store.set("startedTime",res.payload.test_started);
        //  store.set("duration",res.payload.test_duration);
        this.time_started = res.payload.time_started;
        this.remainingTime =
          Number(res.payload.test_duration || this.props.examDuration) +
          Number(
            moment(res.payload.time_started)
              .subtract(res.payload.time_check)
              .format("x")
          );
        if (
          window.location.host.includes("vijna.onesaz") ||
          window.location.host.includes("sr.onesaz") ||
          (window.location.host.includes("excellencia.onesaz") &&
            ![
              "X0210779",
              "X0210771",
              "X0210770",
              "X0210769",
              "X0210768",
              "X0210772",
              "X0210773",
              "X0210774",
              "X0210746",
              "X0210747",
            ].includes(`${store.get("user").level}`)) ||
          window.location.host.includes("tirumala.onesaz") ||
          window.location.host == "onesaz" ||
          window.location.host.includes("localhost")
        ) {
          //  console.log(moment(res.payload.endDateTime).format("x"));
          const endDateTime = Number(
            moment(res.payload.endDateTime).format("x")
          );
          this.remainingTime = Number(
            moment(endDateTime)
              .subtract(res.payload.time_check)
              .format("x")
          );
          console.log(
            res.payload.time_started,
            endDateTime,
            this.remainingTime
          );
        }
        this.timer = setInterval(() => {
          this.remainingTime = this.remainingTime - 1000;
          if (this.remainingTime < 0) {
            clearInterval(this.timer);
            this.displayTime = 0;
            //alert("Exam timeline finished");
            this.setState({
              confirmOpen: true,
              alertText: "Exam timeline finished",
            });
            this.submit("YES");
            console.log(this.props);
            const { examName, location, paper } = this.props;
            history.push({
              pathname: `/${examName}/new-result`,
              search: `?paper=${paper}&test=${location.state.testDisplayName}`,
            });
          } else {
            const seconds = this.remainingTime / 1000;
            const m = parseInt(seconds / 60) + ""; // Calculate minutes
            const s = parseInt(seconds % 60) + ""; // Calculate remaining seconds
            this.displayTime = [m.padStart(2, "0"), s.padStart(2, "0")].join(
              ":"
            );
          }
          this.forceUpdate();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 429) {
          this.setState({
            confirmOpen: true,
            alertText:
              "Too many reload requests, please try reloading after 1 min.",
          });
        }
      })
      .finally(() => {
        // alert("no internet connection")
      });
  }
  expandQuestion() {
    if (this.expandfromleft.isExpanded) {
      let expandfromleft = {
        left: "90%",
        display: "unset",
        xsLeft: 9,
        xsRight: 3,
        isExpanded: false,
        icon: <KeyboardArrowRightIcon />,
      };
      this.expandfromleft = expandfromleft;
    } else {
      let expandfromleft = {
        left: "93%",
        display: "none",
        xsLeft: 12,
        xsRight: 0,
        isExpanded: true,
        icon: <KeyboardArrowLeftIcon />,
      };
      this.expandfromleft = expandfromleft;
    }
  }

  componentDidUpdate() {
    const math = document.getElementById("student-math"); // window.MathJax.typeset();
    if (math)
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math, () => {}]);
  }

  analyticsUPStream(q_no, type, status, markedAnswer = "NA", subject) {
    let danalyticsData = {
      ...analyticsConstantData,
      Data: {
        exam_name_and_student_id: [this.props.examName, this.props.user].join(
          "_"
        ),
        question_number: q_no,
        action: {
          type: type,
          status: status,
          markedAnswer: markedAnswer,
          subject: subject,
          timestamp: Date.now(),
        },
      },
    };
    analyticsAxios
      .post("", danalyticsData)
      .catch((err) => {
        console.log("error in posting data to upstream");
      })
      .then((res) => {
        // console.log(res);
      });
  }

  setIntegerValue(value) {
    this.state.current_question.markedAnswer = value;
  }

  //handle matrix values
  setMatrixValues = (matrixVal) => {
    this.state.current_question.markedAnswer = matrixVal;
  };

  setTrueFalseValue = (val) => {
    this.state.current_question.markedAnswer = val;
  };

  render() {
    const { classes } = this.props;
    // console.log(this.props);
    let html_current_question = {};
    const options = ["A", "B", "C", "D", "E"];
    //  ['single','partial','multiple','single_partial','float','integer','matrix'];
    if (this.state.current_question) {
      html_current_question.question_number = this.state.current_question.question_number;
      html_current_question.tempQNo = this.state.current_question.tempQNo;

      html_current_question.q = (
        <Grid
          container
          direction="row"
          style={{ backgroundColor: "white", padding: "8px" }}
          className={classes.textWrapper}
          justify="flex-start"
          alignItems="center"
        >
          <div
            style={{ padding: "0 24px 0 24px" }}
            dangerouslySetInnerHTML={{ __html: this.state.current_question.q }}
          />
        </Grid>
      );
      options.forEach((option) => {
        if (this.state.current_question[option]) {
          html_current_question[option] = (
            <span
              className={classes.mcqColor}
              dangerouslySetInnerHTML={{
                __html: this.state.current_question[option],
              }}
            />
          );
        }
      });
      if (
        this.state.current_question.paragraph !== undefined &&
        this.state.current_question.paragraph !== "NA"
      )
        html_current_question.paragraph = (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.textWrapper}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.current_question.paragraph,
              }}
            />
          </Grid>
        );
    }
    // const hiddenflag = "hidden";

    let val = "";
    let sectionInstruction = (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          textAlign="center"
        >
          <Typography className={classes.heading}>Instructions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div">
            {this.props.schema && this.props.schema.customSchema ? (
              <CustomInstructions
                question_number={this.state.current_question.tempQNo}
                schema={this.props.schema.customSchema}
              />
            ) : (
              <SectionInstructions
                paper={this.props.paper}
                schema={schemas[this.props.paper]}
                question_number={this.state.current_question.tempQNo}
              />
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );

    switch (this.state.current_question.q_type) {
      /// for single option
      case "matrix":
      case "matrix_p":
        val = (
          <React.Fragment>
            <Typography
              component="div"
              className="qpanel"
              style={{
                whiteSpace: "nowrap",
                padding: "0px 24px",
                minWidth: "100%",
                maxWidth: "100%",
              }}
            >
              {sectionInstruction}
              {html_current_question.paragraph !== undefined && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Paragraph
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      {html_current_question.paragraph}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <Divider variant="middle" />
              <div>
                <h5 className="question">
                  Question({html_current_question.tempQNo})
                </h5>
              </div>

              {html_current_question.q}
              {
                <MatrixInput
                  markedAnswer={
                    this.state.current_question.markedAnswer == "NA"
                      ? {}
                      : this.state.current_question.markedAnswer
                  }
                  setMatrixValues={this.setMatrixValues}
                />
              }
            </Typography>
            <Typography
              component="div"
              style={{
                top: "24em",
                left: this.expandfromleft.left,
                position: "absolute",
                zIndex: "1",
                display: this.props.width !== "lg" ? "none" : "unset",
              }}
            >
              <Fab
                onClick={() => {
                  this.expandQuestion();
                  this.forceUpdate();
                }}
              >
                {this.expandfromleft.icon}
              </Fab>
            </Typography>
          </React.Fragment>
        );
        break;
      case "list":
      case "list_p":
      case "assertion":
      case "assertion_p":
      case "single":
        val = (
          <React.Fragment>
            <Typography
              component="div"
              className="qpanel"
              style={{
                whiteSpace: "nowrap",
                padding: "0px 24px",
                minWidth: "100%",
                maxWidth: "100%",
              }}
            >
              {sectionInstruction}
              {html_current_question.paragraph !== undefined && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Paragraph
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      {html_current_question.paragraph}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <Divider variant="middle" />
              <div>
                {" "}
                <h5 className="question">
                  {" "}
                  Question({html_current_question.tempQNo})
                </h5>
              </div>

              {html_current_question.q}
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" />
                <RadioGroup
                  className={classes.group}
                  value={
                    this.state.current_question.markedAnswer == undefined
                      ? ""
                      : this.state.current_question.markedAnswer
                  }
                  onChange={this.handleChange}
                >
                  {options.map((option) => {
                    if (html_current_question[option]) {
                      return (
                        <FormControlLabel
                          className={classes.labelMargin}
                          checked={
                            this.state.current_question.markedAnswer == option
                          }
                          key={
                            html_current_question.question_number + "_" + option
                          }
                          value={option}
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                              icon={
                                <CheckedOptions option={option}>
                                  {" "}
                                </CheckedOptions>
                              }
                              checkedIcon={
                                <CheckedOptions
                                  style={{ fontSize: "10px" }}
                                  option={option}
                                >
                                  {" "}
                                </CheckedOptions>
                              }
                            />
                          }
                          label={
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                            >
                              &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                              {html_current_question[option]}{" "}
                            </Grid>
                          }
                        />
                      );
                    }
                    return null;
                  })}
                </RadioGroup>
              </FormControl>
            </Typography>
            <Typography
              component="div"
              style={{
                top: "24em",
                left: this.expandfromleft.left,
                position: "absolute",
                zIndex: "1",
                display: this.props.width !== "lg" ? "none" : "unset",
              }}
            >
              <Fab
                onClick={() => {
                  this.expandQuestion();
                  this.forceUpdate();
                }}
              >
                {this.expandfromleft.icon}
              </Fab>
            </Typography>
          </React.Fragment>
        );
        break;
      // for multiple options / partial
      case "multiple":
      case "partial":
      case "multiple_p":
      case "partial_p":
      case "single_partial":
        val = (
          <React.Fragment>
            <Typography
              component="div"
              className="qpanel"
              style={{
                padding: "0px 24px",
                minWidth: "100%",
                maxWidth: "100%",
              }}
            >
              {sectionInstruction}
              {html_current_question.paragraph !== undefined && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Paragraph
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      {html_current_question.paragraph}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <Divider variant="middle" />
              <div>
                {" "}
                <h5 className="question">
                  {" "}
                  Question({html_current_question.tempQNo})
                </h5>
              </div>
              {html_current_question.q}
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" />
                <FormGroup
                  className={classes.group}
                  //value={this.state.current_question.markedAnswer==undefined? "": this.state.current_question.markedAnswer}
                  //onChange={this.handleChange}
                >
                  {options.map((option) => {
                    if (html_current_question[option]) {
                      return (
                        <FormControlLabel
                          className={classes.labelMargin}
                          checked={
                            this.state.current_question.markedAnswer == option
                          }
                          key={
                            html_current_question.question_number + "_" + option
                          }
                          value={option}
                          control={
                            <Checkbox
                              checked={this.isChecked(option)}
                              onChange={this.handleMultiple(option)}
                              classes={{
                                root: classes.multipleradio,
                                checked: classes.multiplechecked,
                              }}
                            />
                          }
                          label={
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                            >
                              &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                              {html_current_question[option]}{" "}
                            </Grid>
                          }
                        />
                      );
                    }
                    return null;
                  })}
                </FormGroup>
              </FormControl>
            </Typography>
            <Typography
              component="div"
              style={{
                top: "24em",
                left: this.expandfromleft.left,
                position: "absolute",
                zIndex: "1",
                display: this.props.width !== "lg" ? "none" : "unset",
              }}
            >
              <Fab
                onClick={() => {
                  this.expandQuestion();
                  this.forceUpdate();
                }}
              >
                {this.expandfromleft.icon}
              </Fab>
            </Typography>
          </React.Fragment>
        );
        break;
      case "integer":
      case "float":
      case "integer_p":
      case "positive_integer":
      case "float_p":
        val = (
          <React.Fragment>
            <Typography
              component="div"
              className="qpanel"
              style={{
                padding: "0px 24px",
                minWidth: "100%",
                maxWidth: "100%",
              }}
            >
              {sectionInstruction}
              {html_current_question.paragraph !== undefined && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Paragraph
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      {html_current_question.paragraph}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <div>
                {" "}
                <h5 className="question">
                  {" "}
                  Question({html_current_question.tempQNo})
                </h5>
              </div>
              {html_current_question.q}
              <GridContainer direction="row" justify="center">
                <GridItem xs={6} sm={4}>
                  <InputIntegerPanel
                    markedanswer={
                      this.state.current_question.markedAnswer == "NA"
                        ? ""
                        : this.state.current_question.markedAnswer
                    }
                    setIntegerValue={this.setIntegerValue}
                    q_type={this.state.current_question.q_type}
                  >
                    {" "}
                  </InputIntegerPanel>
                </GridItem>
              </GridContainer>
            </Typography>
            <Typography
              component="div"
              style={{
                top: "24em",
                left: this.expandfromleft.left,
                position: "absolute",
                zIndex: "1",
                display: this.props.width !== "lg" ? "none" : "unset",
              }}
            >
              <Fab
                onClick={() => {
                  this.expandQuestion();
                  this.forceUpdate();
                }}
              >
                {this.expandfromleft.icon}
              </Fab>
            </Typography>
          </React.Fragment>
        );
        break;
      case "true_false":
        val = (
          <React.Fragment>
            <Typography
              component="div"
              className="qpanel"
              style={{
                padding: "0px 24px",
                minWidth: "100%",
                maxWidth: "100%",
              }}
            >
              {sectionInstruction}
              {html_current_question.paragraph !== undefined && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Paragraph
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      {html_current_question.paragraph}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <div>
                {" "}
                <h5 className="question">
                  {" "}
                  Question({html_current_question.tempQNo})
                </h5>
              </div>
              {html_current_question.q}
              <GridContainer direction="row" justify="center">
                <GridItem xs={6} sm={4}>
                  <TrueFalseInput
                    markedAnswer={
                      this.state.current_question.markedAnswer == "NA"
                        ? null
                        : this.state.current_question.markedAnswer
                    }
                    setTrueFalseValue={this.setTrueFalseValue}
                  />
                </GridItem>
              </GridContainer>
            </Typography>
            <Typography
              component="div"
              style={{
                top: "24em",
                left: this.expandfromleft.left,
                position: "absolute",
                zIndex: "1",
                display: this.props.width !== "lg" ? "none" : "unset",
              }}
            >
              <Fab
                onClick={() => {
                  this.expandQuestion();
                  this.forceUpdate();
                }}
              >
                {this.expandfromleft.icon}
              </Fab>
            </Typography>
          </React.Fragment>
        );
        break;
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          <AppBar position="static" color="inherit">
            <Toolbar direction="row" justify="flex-end">
              <img
                style={{ width: "100px", height: 35 }}
                src={onesazlogo}
                alt="..."
              />

              <GridContainer
                className={classes.root}
                justify="flex-end"
                style={{ marginRight: "8%" }}
              >
                <Hidden xsDown>
                  <GridItem xs={7}>
                    <h3>
                      <strong> {this.props.displayName}</strong>
                    </h3>
                  </GridItem>
                </Hidden>
                <Hidden smUp>
                  <GridItem xs={9}>
                    <h6>{this.props.displayName}</h6>
                  </GridItem>
                </Hidden>
              </GridContainer>
            </Toolbar>
          </AppBar>
          <Hidden smDown>
            <GridContainer
              justify="flex-start"
              style={{ padding: "0px 10px" }}
              className={classes.instructionsBanner}
            >
              <GridItem xs={6} flexgrow={9}>
                <h5 className={classes.instructionsHeading}>
                  {this.props.paper !== "undefined" && (
                    <strong> Paper: {this.props.paper}</strong>
                  )}
                </h5>
              </GridItem>
              <GridItem xs={3} flexgrow={3}>
                <h5 style={{ color: "white" }}>
                  <img src={timeLeft} style={{ width: "33px" }} alt="" />
                  &nbsp; &nbsp; TIME LEFT
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {" "}
                    {this.remainingTime > 0 ? (
                      <span
                        style={{
                          background: "#0098DA",
                          borderRadius: "25px",
                          color: "white",
                          fontWeight: "600",
                          padding: "2px 4px",
                        }}
                      >
                        {this.displayTime}{" "}
                      </span>
                    ) : (
                      <span className={classes.expired}>
                        {" "}
                        &nbsp;&nbsp;expired &nbsp;&nbsp;
                      </span>
                    )}
                  </span>
                </h5>
              </GridItem>
              <GridItem xs>
                <h5 className={classes.instructionsHeading}>
                  <QuestionPaperDialog questions={this.state.questions} />
                </h5>
              </GridItem>
              <GridItem xs>
                <h5 className={classes.instructionsHeading}>
                  <InstructionDialog> </InstructionDialog>
                </h5>
              </GridItem>
            </GridContainer>
          </Hidden>
          <Hidden mdUp xsDown>
            <GridContainer
              justify="flex-start"
              style={{ padding: "0px 10px" }}
              className={classes.instructionsBanner}
            >
              <GridItem xs={4} flexgrow={3}>
                <h5 className={classes.instructionsHeading}>
                  <strong> Paper: {this.props.paper}</strong>
                </h5>
              </GridItem>
              <GridItem xs={4} flexgrow={3}>
                <h5 style={{ color: "white" }}>
                  <img src={timeLeft} style={{ width: "33px" }} alt="" />
                  &nbsp; &nbsp; TIME LEFT
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {" "}
                    {this.remainingTime > 0 ? (
                      <span
                        style={{
                          background: "#0098DA",
                          borderRadius: "25px",
                          color: "white",
                          fontWeight: "600",
                          padding: "2px 4px",
                        }}
                      >
                        {this.displayTime}{" "}
                      </span>
                    ) : (
                      <span className={classes.expired}>
                        {" "}
                        &nbsp;&nbsp;expired &nbsp;&nbsp;
                      </span>
                    )}
                  </span>
                </h5>
              </GridItem>
              <GridItem xs>
                <h5 className={classes.instructionsHeading}>
                  <QuestionPaperDialog questions={this.state.questions} />
                </h5>
              </GridItem>
              <GridItem xs>
                <h5 className={classes.instructionsHeading}>
                  <InstructionDialog> </InstructionDialog>
                </h5>
              </GridItem>
            </GridContainer>
          </Hidden>
          <Hidden smUp>
            <GridContainer
              gutter={false}
              justify="space-evenly"
              style={{ padding: "0px 10px" }}
              className={classes.instructionsBanner}
            >
              <GridItem xs>
                <h5 className={classes.instructionsHeading}>
                  <strong style={{ fontSize: "14px" }}>
                    {" "}
                    {this.props.paper}
                  </strong>
                </h5>
              </GridItem>
              <GridItem xs>
                <h5 style={{ color: "white" }}>
                  <img src={timeLeft} style={{ width: "20px" }} alt="" />
                  {/* &nbsp; &nbsp; TIME LEFT */}
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {" "}
                    {this.remainingTime > 0 ? (
                      <span
                        style={{
                          background: "#0098DA",
                          borderRadius: "25px",
                          color: "white",
                          fontWeight: "600",
                          padding: "2px 4px",
                          fontSize: "14px",
                        }}
                      >
                        {this.displayTime}{" "}
                      </span>
                    ) : (
                      <span className={classes.expired}>
                        {" "}
                        &nbsp;&nbsp;expired &nbsp;&nbsp;
                      </span>
                    )}
                  </span>
                </h5>
              </GridItem>
              <GridItem xs={1}>
                <h5 className={classes.instructionsHeading}>
                  <QuestionPaperDialog questions={this.state.questions} />
                </h5>
              </GridItem>
              <GridItem xs={2}>
                <h5 className={classes.instructionsHeading}>
                  <InstructionDialog> </InstructionDialog>
                </h5>
              </GridItem>
            </GridContainer>
          </Hidden>
          <CacheImg imageURLs={this.state.imageURLs} />
          {this.questions.length > 0 && !this.state.ifImagesLoading ? (
            <React.Fragment>
              {!this.isSubmitted ? (
                <GridContainer>
                  {/* <Grid item xs={12} className={classes.newgridsep}></Grid>    */}
                  <GridItem xs={12} sm={this.expandfromleft.xsLeft}>
                    <GridContainer style={{ backgroundColor: "white" }}>
                      {/* <div style={{ backgroundColor: 'white', }} > */}
                      <GridItem
                        style={{ display: "grid", overflowX: "scroll" }}
                      >
                        <AdvanceSubjects
                          questions={this.questions}
                          examname={this.props.examName}
                          schema={
                            this.props.schema
                              ? this.props.schema
                              : schemas[this.props.paper]
                          }
                          current_question={this.state.current_question}
                          qNumPerSubject={this.state.qNumPerSubject}
                          question_number={html_current_question.tempQNo}
                          displayQuestion={this.displayQuestion.bind(this)}
                        />
                      </GridItem>
                      <GridItem
                        style={{
                          minHeight: "25px",
                          paddingRight: "0px",
                          background: "#48A5FF",
                        }}
                      />
                      {/* </div> */}
                    </GridContainer>
                    {/* <Grid item xs={12} className={classes.newgridsep}></Grid>  */}

                    <GridContainer
                      id="student-math"
                      style={{ background: "white" }}
                    >
                      {val}
                    </GridContainer>

                    {/* attempt  */}
                    <Hidden smUp>
                      <GridContainer
                        justify="space-evenly"
                        id="buttonContainer"
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                          paddingLeft: "4%",
                        }}
                      >
                        <GridItem xs={8} sm>
                          <Button
                            style={{ width: "100%" }}
                            size="sm"
                            onClick={() => {
                              this.attempt(
                                "MARKED_FOR_REVIEW",
                                html_current_question.tempQNo,
                                false
                              );
                            }}
                            className={classNames(
                              classes.btndefault,
                              classes.btnAttempt
                            )}
                          >
                            Mark for Review & Next
                          </Button>
                        </GridItem>
                        <GridItem xs={8} sm>
                          <Button
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => {
                              this.clear(html_current_question.tempQNo);
                            }}
                            className={classNames(
                              classes.btndefault,
                              classes.btnAttempt
                            )}
                          >
                            Clear Response
                          </Button>
                        </GridItem>
                        <GridItem xs={8} sm>
                          <Button
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => {
                              this.attempt(
                                "ANSWERED",
                                html_current_question.tempQNo
                              );
                            }}
                            className={classNames(
                              classes.btnsuccess1,
                              classes.btnAttempt
                            )}
                          >
                            Save & Next
                          </Button>
                        </GridItem>

                        <GridItem xs={8} sm>
                          <Tooltip
                            title={`You can submit the exam only before ${submitDisabledDuration[
                              window.location.host
                            ] / 60} mins of the end time.`}
                            placement="top"
                          >
                            <span>
                              <Button
                                size="md"
                                disabled={
                                  this.remainingTime / 1000 >
                                  submitDisabledDuration[window.location.host]
                                }
                                onClick={() => this.submit()}
                                className={classes.btnSubmit}
                                style={{
                                  display:
                                    this.expandfromleft.display == "none"
                                      ? "unset"
                                      : "none",
                                }}
                              >
                                Submit
                              </Button>
                            </span>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    </Hidden>
                    <Hidden only={["xs"]}>
                      <GridContainer
                        justify="space-evenly"
                        id="buttonContainer"
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                      >
                        <GridItem xs={12} sm={4} md={4}>
                          <Button
                            onClick={() => {
                              this.attempt(
                                "MARKED_FOR_REVIEW",
                                html_current_question.tempQNo,
                                false
                              );
                            }}
                            className={classNames(
                              classes.btndefault,
                              classes.btnAttempt
                            )}
                          >
                            Mark for Review & Next
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={5} md>
                          <Button
                            xs={6}
                            onClick={() => {
                              this.clear(html_current_question.tempQNo);
                            }}
                            className={classNames(
                              classes.btndefault,
                              classes.btnAttempt
                            )}
                          >
                            Clear Response
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={3} md>
                          <Button
                            onClick={() => {
                              this.attempt(
                                "ANSWERED",
                                html_current_question.tempQNo
                              );
                            }}
                            className={classNames(
                              classes.btnsuccess1,
                              classes.btnAttempt
                            )}
                          >
                            Save & Next
                          </Button>
                        </GridItem>

                        <GridItem xs={12} sm md>
                          <Button
                            size="md"
                            disabled={
                              this.remainingTime / 1000 >
                              submitDisabledDuration[window.location.host]
                            }
                            onClick={() => this.submit()}
                            className={classes.btnSubmit}
                            style={{
                              display:
                                this.expandfromleft.display == "none"
                                  ? "unset"
                                  : "none",
                            }}
                          >
                            Submit
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Hidden>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={this.expandfromleft.xsRight}
                    style={{ display: this.expandfromleft.display }}
                  >
                    <GridContainer spacing={0}>
                      <GridItem>
                        {/* <Grid item xs={12} className={this.props.width!=="lg"?classes.newgridsep:''}></Grid> */}
                        <Typography
                          component="div"
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <AdvanceNumbersPanel
                            remainingTime={this.remainingTime}
                            submit={this.submit}
                            schema={
                              this.props.schema
                                ? this.props.schema
                                : schemas[this.props.paper]
                            }
                            displayQuestion={this.displayQuestion.bind(this)}
                            questions={this.state.questions}
                            current_question={this.state.current_question}
                          >
                            {" "}
                          </AdvanceNumbersPanel>
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              ) : (
                <ExamSummaryAdv
                  type={this.state.confirmationtType}
                  statusdisplay={this.statusdisplay}
                  submit={this.submit}
                  questions={this.questions}
                  examname={this.props.examName}
                  schema={
                    this.props.schema
                      ? this.props.schema
                      : schemas[this.props.paper]
                  }
                  qNumPerSubject={this.state.qNumPerSubject}
                  question_number={html_current_question.question_number}
                >
                  {" "}
                </ExamSummaryAdv>
              )}{" "}
            </React.Fragment>
          ) : (
            <NetworkProgress />
          )}

          {/* new end  */}
        </div>
        <AlertComponent
          open={this.state.confirmOpen}
          closeAlert={this.handleCloseAlertBox}
          ifThemeRequired={false}
          alertType={this.state.alertType}
        >
          {this.state.alertText}
        </AlertComponent>
      </React.Fragment>
    );
  }
}

export default compose(
  withWidth(),
  withRouter,
  withStyles(styles)
)(Advance);
