const getSomeOptions = (caste) => {
  switch (caste) {
    case "GENERAL":
      return ["GENERAL"];
    case "OBC":
      return ["OBC", "BC-A", "BC-B", "BC-C", "BC-D", "BC-E", "OTHER"];
    case "SC":
      return ["SC-B", "SC-C", "OTHER"];
    case "ST":
      return ["ST", "OTHER"];
    default:
      return [];
  }
};

export const StudentDetailsFields = [
  {
    field: "fullName",
    type: "text",
    key: "fullName",
    label: "First Name",
  },
  {
    field: "lastName",
    type: "text",
    key: "lastName",
    label: "Last Name",
  },
  {
    field: "dateOfBirth",
    type: "date",
    key: "dateOfBirth",
    label: "Date of Birth",
  },
  {
    field: "aadharNo",
    type: "number",
    key: "aadharNo",
    label: "Aadhar Number",
  },
  {
    field: "gender",
    type: "select",
    key: "gender",
    label: "Gender",
    options: ["Male", "Female"],
  },
  {
    field: "admissionType",
    type: "select",
    key: "admissionType",
    label: "Admission Type",
    options: ["DAY SCHOLAR", "RESIDENTIAL"],
  },
  {
    field: "nationality",
    type: "countrySelect",
    key: "additionalInfo.nationality",
    label: "Nationality",
  },
  {
    field: "religion",
    type: "select",
    key: "additionalInfo.religion",
    label: "Religion",
    options: [
      "Hindu",
      "Islam",
      "Christian",
      // "Sikhism",
      // "Buddhism",
      // "Jainism",
      "Other",
    ],
  },
  {
    field: "caste",
    type: "select",
    key: "additionalInfo.caste",
    label: "Category",
    options: ["GENERAL", "OBC", "SC", "ST"],
    dependent: "subCaste",
  },
  {
    field: "subCaste",
    type: "select",
    key: "additionalInfo.subCaste",
    label: "Sub Category (if any)",
    options: [
      "GENERAL",
      "OBC",
      "BC-A",
      "BC-B",
      "BC-C",
      "BC-D",
      "BC-E",
      "SC-B",
      "SC-C",
      "ST",
      "OTHER",
    ],
    getOptions: (caste) => {
      const options = getSomeOptions(caste);
      return options;
    },
    dependsOn: "caste",
  },
  {
    field: "casteText",
    type: "text",
    key: "additionalInfo.casteText",
    label: "Caste",
  },
];

export const ParentDetailsFields = [
  {
    field: "fatherName",
    type: "text",
    key: "personalDetails.fatherName",
    label: "Father Name",
  },
  {
    field: "fatherNumber",
    type: "number",
    key: "personalDetails.fatherNumber",
    label: "Father Phone No",
  },
  {
    field: "fatherEmail",
    type: "email",
    key: "personalDetails.fatherEmail",
    label: "Father Email",
  },
  {
    field: "fatherQualification",
    type: "text",
    key: "personalDetails.fatherQualification",
    label: "Father Qualification",
  },
  {
    field: "fatherOccupation",
    type: "select",
    key: "personalDetails.fatherOccupation",
    label: "Father Occupation",
    options: [
      "Farmer/Agricultural",
      "Government Service",
      "Teacher/Professor",
      "Defense Personnel (Army, Navy, Air Force)",
      "Doctor/Nurse",
      "Engineer (Software, Civil, Mechanical, etc.)",
      "Business Owner/Entrepreneur",
      "Private Service",
      "Bank Employee",
      "Lawyer",
      "Journalist/Media Professional",
      "Artist/Musician/Actor",
      "Construction Worker",
      "Housewife/Homemaker",
      "Other",
    ],
  },
  {
    field: "motherName",
    type: "text",
    key: "personalDetails.motherName",
    label: "Mother Name",
  },
  {
    field: "motherNumber",
    type: "number",
    key: "personalDetails.motherNumber",
    label: "Mother Phone No",
  },
  {
    field: "motherEmail",
    type: "email",
    key: "personalDetails.motherEmail",
    label: "Mother Email",
  },
  {
    field: "motherQualification",
    type: "text",
    key: "personalDetails.motherQualification",
    label: "Mother Qualification",
  },
  {
    field: "motherOccupation",
    type: "select",
    key: "personalDetails.motherOccupation",
    label: "Mother Occupation",
    options: [
      "Farmer/Agricultural",
      "Government Service",
      "Teacher/Professor",
      "Defense Personnel (Army, Navy, Air Force)",
      "Doctor/Nurse",
      "Engineer (Software, Civil, Mechanical, etc.)",
      "Business Owner/Entrepreneur",
      "Private Service",
      "Bank Employee",
      "Lawyer",
      "Journalist/Media Professional",
      "Artist/Musician/Actor",
      "Construction Worker",
      "Housewife/Homemaker",
      "Other",
    ],
  },
  {
    field: "guardianName",
    type: "text",
    key: "personalDetails.guardianName",
    label: "Guardian Name",
  },
  {
    field: "guardianNumber",
    type: "number",
    key: "personalDetails.guardianNumber",
    label: "Guardian Number",
  },
  // {
  //   field: "studentEmail",
  //   type: "email",
  //   key: "personalDetails.studentEmail",
  //   label: "Student's Email",
  // },
  // {
  //   field: "mobileNo",
  //   type: "text",
  //   key: "personalDetails.mobileNo",
  //   label: "Mobile Number",
  // },
  {
    field: "alternateMobileNo",
    type: "number",
    key: "personalDetails.alternateMobileNo",
    label: "Alternate Mobile Number",
  },
];

export const AddressFieldDetails = [
  {
    field: "houseNo",
    type: "text",
    key: "address.houseNo",
    label: "House No./Flat No./Apartment",
  },
  {
    field: "street",
    type: "text",
    key: "address.street",
    label: "Street",
  },
  {
    field: "village",
    type: "text",
    key: "address.village",
    label: "Village",
  },
  {
    field: "city",
    type: "text",
    key: "address.city",
    label: "City",
  },
  {
    field: "district",
    type: "text",
    key: "address.district",
    label: "District",
  },
  {
    field: "state",
    type: "text",
    key: "address.state",
    label: "State",
  },
  {
    field: "pincode",
    type: "number",
    key: "address.pincode",
    label: "Pincode",
  },
  {
    field: "howDoYouKnowAboutUs",
    type: "select",
    key: "howDoYouKnowAboutUs",
    label: "How Do You Know About Us",
    options: [
      "News Paper",
      "Walk-in",
      "Website",
      "Others",
      "Reference",
    ],
  },
  {
    field: "referenceDesc",
    type: "text",
    key: "referenceDesc",
    label: "Reference name & contact",
  },
];

export const PreviousSchoolDetails = [
  {
    field: "preSchoolName",
    type: "text",
    key: "preSchoolDetails.preSchoolName",
    label: "Previous School Name",
  },
  {
    field: "preClass",
    type: "number",
    key: "preSchoolDetails.preClass",
    label: "Previous Class",
  },
  {
    field: "preClassPercentage",
    type: "number",
    key: "preSchoolDetails.preClassPercentage",
    label: "Previous Class Percentage",
  },
  {
    field: "preSchoolBoard",
    type: "select",
    key: "preSchoolDetails.preSchoolBoard",
    label: "Previous School Board",
    options: ["CBSE", "ICSE", "STATE", "OTHERS"],
  },
  {
    field: "reasonForChanging",
    type: "text",
    key: "preSchoolDetails.reasonForChanging",
    label: "Reason for Changing",
  },
  // {
  //   field: "yearOfPassing",
  //   type: "yearSelect",
  //   key: "preSchoolDetails.yearOfPassing",
  //   label: "Year of Passing",
  // },
  {
    field: "percentageInMaths",
    type: "number",
    key: "preSchoolDetails.percentageInMaths",
    label: "Percentage in Maths",
  },
  {
    field: "percentageInScience",
    type: "number",
    key: "preSchoolDetails.percentageInScience",
    label: "Percentage in Science",
  },
  // {
  //   field: "previousAdmissionNo",
  //   type: "text",
  //   key: "preSchoolDetails.previousAdmissionNo",
  //   label: "Previous Admission Number",
  // },
  {
    field: "address",
    type: "text",
    key: "preSchoolDetails.address",
    label: "Previous School Address(village/town)",
  },
];
