// React and related libraries
import React, { useEffect, useState, useMemo } from "react";
import { Formik, Form } from "formik";
import { useHistory, Link, useParams } from "react-router-dom";
import moment from "moment";

// State management and validation
import * as Yup from "yup";
import store from "store";

// Material UI components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom components and hooks
import OtpInput from "./OtpInput";
import useFetch from "../../hooks/useFetch";
import { useSnackbar } from "../../context/snackbar";
import BoxCountdown from "../../components/CountDownTimer";
import StyledBox from "../../components/StyledBox";
import Guidelines from "./Guidelines";
import RegFooter from "./RegFooter";
import ConfirmationDialog from "../../components/ConfirmationDialog.jsx";

// Theme and assets
import theme from "../../assets/v3/theme";
import IndFlag from "../../assets/svgs/ind.svg";
import colors from "../../assets/v3/base/colors";
import LogoURLMap, { hostnamesMap } from "../../components/LogoURLMap";

// API services
import {
  createOtpRegister,
  newRegister,
  getRegistrationFormConfig,
} from "../../apis/UserService";

// form validation schema
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be a number")
    .min(10, "Phone number must be 10 characters")
    .max(10, "Phone number must be 10 characters")
    .required("Phone number is required"),
  branch: Yup.string().required("Branch is required"),
  grade: Yup.string().required("Class is required"),
  batch: Yup.string().required("Course is required"),
  boards: Yup.string().required("Board is required"),
});

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    textAlign: "left",
  },
  label: {
    lineHeight: "1.5",
    // marginBottom: theme.spacing(0.5),
  },
  inputWithIcon: {
    border: "none",
  },
  titleContainer: {
    color: `linear-gradient(95.69deg, #ED1B24 0%, #074EA0 100%)`,
  },
  heading: {
    textAlign: "center",
    lineHeight: 1.5,
    fontWeight: "bolder",
    background: `-webkit-linear-gradient(#ED1B24, #074EA0)`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  back: {
    paddingLeft: 0,
    color: colors.dark.main,
  },
  option: {
    fontWeight: "400",
    textDecoration: "none",
    textTransform: "none",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginLeft: theme.spacing(1),
  },
  blueGradientBtn: {
    background: `linear-gradient(310deg, ${colors.gradients.tirumala.main}, ${
      colors.gradients.tirumala.state
    })`,
    color: "white",
  },
  blueGradientBtn2: {
    border: "1px solid transparent",
    background: `
      linear-gradient(to right, white, white), 
      linear-gradient(to right, ${colors.gradients.tirumala.state}, ${
      colors.gradients.tirumala.main
    })
    `,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    color: colors.gradients.tirumala.state,
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.info.dark,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  pageTitleSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 900,
    flexGrow: 1,
  },
  pageTitle: {
    color: colors.white.main,
    flexGrow: 1,
  },
  nextText: {
    color: colors.white.main,
  },
  formContainer: {
    backgroundColor: colors.background.default,
    [theme.breakpoints.up("md")]: {
      boxShadow: theme.shadows[3],
      borderRadius: theme.spacing(2),
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(10),
    },
  },
  relative: {
    position: "relative",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitleText: {
    color: colors.info.dark,
    flexGrow: 1,
    fontWeight: "bold",
  },
  cardSubTitle: {
    color: colors.info.dark,
  },
}));

export const PageTitle = ({ title, next }) => {
  const classes = useStyles();
  return (
    <Box className={classes.pageTitleContainer}>
      <Box className={classes.pageTitleSubContainer}>
        <Typography variant="h5" className={classes.pageTitle}>
          {title}
        </Typography>
        {next && (
          <Typography
            variant="caption"
            className={classes.nextText}
            component="p"
          >
            next: {next}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const CardPageTitle = ({ title, next }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardTitle} flexGrow={1}>
      <Box className={classes.pageTitleSubContainer}>
        <Typography variant="h5" className={classes.cardTitleText}>
          {title}
        </Typography>
        {next && (
          <Typography
            variant="caption"
            className={classes.cardSubTitle}
            component="p"
          >
            next: {next}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const NewRegistration = () => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [l1, e1, d1, f1, r1] = useFetch(createOtpRegister);
  const [l2, e2, d2, f2, r2] = useFetch(newRegister);
  const [l3, e3, d3, f3, r3] = useFetch(getRegistrationFormConfig);
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(90);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [boards, setBoards] = useState(null);
  const [grade, setGrade] = useState(null);
  const [branch, setBranch] = useState(null);
  const [batch, setBatch] = useState(null);
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const { branch: paramBranch } = useParams();
  const instituteId =
    hostnamesMap[window.location.hostname] || "5d679d49c136660a09596d85";
  const payload = d3 ? d3.payload : {};
  const sysTime = d3 ? d3.sysTime : null;
  const title = payload && payload.title ? payload.title : null;
  const stuff = LogoURLMap[instituteId] || {};
  const academicYear =
    payload && payload.academicYear ? payload.academicYear : null;
  const branchesList = d3 ? d3.branchesList : [];
  const branchesData = d3 ? d3.branchesData : {};
  const batchesData = d3 ? d3.batchesData : {};
  const date = useMemo(() => {
    if (payload) {
      if (
        payload &&
        payload[`${paramBranch}`] &&
        payload[`${paramBranch}`].date
      ) {
        return payload[`${paramBranch}`].date;
      }
      if (payload && payload.date) {
        return payload.date;
      }
      return null;
    }
    return null;
  }, [payload, paramBranch]);

  const clear = () => {
    setOtpSent(false);
    r1();
    r2();
  };

  const countDown = useMemo(() => {
    if (!date || !sysTime) return null;
    const from = new Date(sysTime).getTime();
    const to = new Date(date).getTime();
    const difference = to - from;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }, [date, sysTime]);

  const submitOtp = (values) => {
    const myGrade = `${grade}`.padStart(2, "0");
    const myCourse = (() => {
      if (batch.toLowerCase().includes("jee_main")) {
        return "JEE_MAIN";
      }
      if (batch.toLowerCase().includes("jee_advanced")) {
        return "JEE_ADVANCED";
      }
      if (batch.toLowerCase().includes("neet")) {
        return "NEET";
      }
      if (batch.toLowerCase().includes("jee-main")) {
        return "JEE_MAIN";
      }
      if (batch.toLowerCase().includes("jee-advanced")) {
        return "JEE_ADVANCED";
      }
      return batchesData[batch];
    })();
    const data = {
      phoneNumber,
      branchId: branch,
      batch: batchesData[batch],
      academicYear,
      current_ay: academicYear,
      tt: true,
      otp: values.otp,
      course: myCourse,
      boards,
      grade: myGrade,
      filterKey: `${branch}_${boards}_${myGrade}`,
      class: `${grade}`,
    };
    f2(data);
  };

  useEffect(() => {
    let timer;
    if (otpSent) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent]);

  useEffect(() => {
    if (d1) {
      if (d1 && d1.data && d1.data.status === "SUCCESS") {
        openSnackbar("OTP sent successfully", "success");
        setOtpSent(true);
        setResendTimer(90);
        setIsResendDisabled(true);
      } else if (
        d1.data &&
        d1.data.status === "FAILURE" &&
        d1.data.message === "The phone number already exists in our records."
      ) {
        openSnackbar(d1.data.message, "warning", false, {
          customActions: [
            {
              text: "Login",
              onClick: () => history.push("/reg-login"),
              className: "success",
              closeOnClick: true,
            },
          ],
          showCloseIcon: true,
          actionsPosition: "center",
          onClose: () => history.push("/reg-login"),
          autoClose: true,
          triggerType: "none",
        });
      } else if (
        d1.data &&
        d1.data.status === "FAILURE" 
      ) {
        openSnackbar(d1.data.message, "warning", false);
      }
    }

    if (e1) {
      openSnackbar("Failed to send OTP", "error");
    }

    return () => {
      r1();
    };
  }, [d1, e1, openSnackbar]);

  const resendOtp = () => {
    // logic to resend OTP
    f1({
      phoneNumber,
      branchName: branchesData[branch] ? branchesData[branch].branchName : "",
    });
    setOtpSent(true);
    setResendTimer(90);
    setIsResendDisabled(true);
  };

  const imageKey = useMemo(() => {
    if(paramBranch) {
      return `url_${paramBranch}`;
    }
    return "url";
  }, [paramBranch, branch]);

  useEffect(() => {
    if (d2) {
      if (d2.data.status === "FAILURE") {
        openSnackbar(d2.data.message, "error");
      } else {
        openSnackbar("Registration successful", "success");
        localStorage.setItem("isAuthenticated", true);
        store.set("user", d2.data);
        history.push(`/student-details`);
      }
    }
    if (e2) {
      openSnackbar(e2, "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2, openSnackbar]);

  useEffect(() => {
    if (d3) {
      if (d3.data && d3.data.status === "FAILURE") {
        openSnackbar(d3.data.message, "error");
      }
    }

    if (e3) {
      openSnackbar("Failed to fetch registration form config", "error");
    }
  }, [d3, e3, openSnackbar]);

  useEffect(() => {
    f3({ page: "new-registration", instituteId });

    return () => {
      r3();
    };
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (paramBranch) {
      setBranch(paramBranch);
    }
  }, [paramBranch]);

  const isValidBranch = useMemo(() => {
    if (!paramBranch) return true;
    if (!branchesList) return false;
    if (branchesList.length === 0) return false;
    return branchesList.find((branch) => branch.branchCode === paramBranch);
  }, [branchesList, paramBranch]);

  const classes = useStyles();

  const boards_options = ["STATE", "CBSE", "ICSE"];

  const formatTime = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    return `${duration.minutes()}:${duration
      .seconds()
      .toString()
      .padStart(2, "0")}`;
  };

  const getSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
  };

  const renderOptionText = (number) => {
    return (
      <> Into&nbsp;&nbsp;
        {number}
        <sup>{getSuffix(Number(number))}</sup> Class
      </>
    );
  };

  useEffect(() => {
    if (branchesList && branchesList.length === 1) {
      setBranch(branchesList[0].branchCode);
    }
  }, [branchesList]);

  useEffect(() => {
    if (grade && branch) {
      const some = branchesData[branch].batches;
      if (some && some[grade] && some[grade].length === 1) {
        setBatch(some[grade][0]);
      }
    }
  }, [grade, branch]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleConfirmDialogOpen = () => {
    if(branch === "VSKP" || branch === "RJY" || branch === "BVM") {
      openSnackbar("Registrations for AVAGAHANA SADASSU (2025-2026) are closed", "warning", false);
      return;
    }
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogConfirm = () => {
    setConfirmDialogOpen(false);
    f1({
      phoneNumber,
      branchName: branchesData[branch] ? branchesData[branch].branchName : "",
    });
  };

  const renderForm = () => {
    if (otpSent) {
      return (
        <StyledBox borderRadius="lg" shadow="lg" padding={2}>
          <Box maxWidth={400} margin="auto" mt={2} mb={2}>
            <Button
              onClick={() => clear()}
              className={classes.back}
              size="small"
              variant="text"
            >
              <ArrowBackIosRoundedIcon />
              &nbsp;Back
            </Button>
            <Typography variant="h3" gutterBottom>
              Verify OTP
            </Typography>
            <Typography variant="body2">
              Please enter the 6-digit verification code sent to you at +91-
              {phoneNumber}
            </Typography>
          </Box>
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={Yup.object().shape({
              otp: Yup.string()
                .matches(/^[0-9]+$/, "OTP must be a number")
                .min(6, "OTP is required")
                .max(6, "OTP is required")
                .required("OTP is required"),
            })}
            onSubmit={(values) => {
              submitOtp(values);
            }}
          >
            {({ errors, touched, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Box maxWidth={400} margin="auto">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.title}
                  >
                    Enter OTP
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <OtpInput
                        handleOtpChange={(otp) => {
                          setFieldValue("otp", otp);
                        }}
                        containerStyles={{ justifyContent: "flex-start" }}
                      />
                      {touched.otp && errors.otp && (
                        <Typography color="error" variant="caption">
                          {errors.otp}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={l2}
                          className={classes.blueGradientBtn}
                        >
                          {l2 ? "Verifying OTP..." : "Submit OTP"}
                        </Button>
                        <Button
                          onClick={resendOtp}
                          color="secondary"
                          disabled={isResendDisabled}
                          variant="text"
                        >
                          {isResendDisabled
                            ? `Resend OTP in ${formatTime(resendTimer)}`
                            : "Resend OTP"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </StyledBox>
      );
    }
    return (
      <StyledBox
        borderRadius="lg"
        shadow="lg"
        padding={4}
        className={classes.relative}
      >
        <Guidelines />
        <Box textAlign="center">
          {stuff && <img src={stuff[imageKey]} alt="Institute Logo" height="120" />}
        </Box>
        <Formik
          initialValues={{
            branch,
            batch,
            phoneNumber,
            grade,
            boards,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setBranch(values.branch || null);
            setGrade(values.grade || null);
            setBatch(values.batch || null);
            setBoards(values.boards || null);
            setPhoneNumber(values.phoneNumber || "");
            handleConfirmDialogOpen();
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const currentBatches =
              values.branch && values.grade
                ? branchesData[values.branch].batches[values.grade]
                : [];

            return (
              <Form onSubmit={handleSubmit}>
                <Box maxWidth={320} margin="auto">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box marginTop={1}>
                        <Typography variant="body2" className={classes.label}>
                          Select Campus<b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Box>
                      <AutoComplete
                        id="branch"
                        name="branch"
                        options={branchesList}
                        value={
                          values.branch
                            ? {
                                branchName:
                                  branchesData[values.branch].branchName,
                                branchCode: values.branch,
                              }
                            : null
                        }
                        getOptionLabel={(option) => option.branchName}
                        onChange={(_, value) => {
                          setFieldValue("grade", null);
                          setGrade(null);

                          setFieldValue("batch", null);
                          setBatch(null);

                          setFieldValue(
                            "branch",
                            value ? value.branchCode : null
                          );
                          setBranch(value ? value.branchCode : null);
                        }}
                        renderInput={(params) => {
                          if (
                            (branchesList && branchesList.length === 1) ||
                            paramBranch
                          ) {
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                error={touched.branch && Boolean(errors.branch)}
                                helperText={touched.branch && errors.branch}
                                InputProps={{ disabled: true }}
                              />
                            );
                          }
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              color="secondary"
                              fullWidth
                              error={touched.branch && Boolean(errors.branch)}
                              helperText={touched.branch && errors.branch}
                            />
                          );
                        }}
                        renderOption={(option) => (
                          <Typography
                            variant="button"
                            className={classes.option}
                          >
                            {option.branchName}
                          </Typography>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box marginTop={1}>
                        <Typography variant="body2" className={classes.label}>
                          {/* Select Class */}
                          Seeking Admission<b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Box>
                      <AutoComplete
                        id="grade"
                        name="grade"
                        options={
                          values.branch
                            ? branchesData[values.branch].classes
                            : []
                        }
                        value={values.grade}
                        onChange={(e, value) => {
                          setBatch(null);
                          setFieldValue("batch", null);

                          setFieldValue("grade", value);
                          setGrade(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            error={touched.grade && Boolean(errors.grade)}
                            helperText={touched.grade && errors.grade}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            variant="button"
                            className={classes.option}
                          >
                            {renderOptionText(option)}
                          </Typography>
                        )}
                      />
                    </Grid>
                    {currentBatches.length > 1 && (
                      <Grid item xs={12}>
                        <Box marginTop={1}>
                          <Typography variant="body2" className={classes.label}>
                            Select Course<b style={{ color: "red" }}>*</b>
                          </Typography>
                        </Box>
                        <AutoComplete
                          id="batch"
                          name="batch"
                          options={currentBatches}
                          value={values.batch || ""}
                          onChange={(e, value) => {
                            setFieldValue("batch", value);
                            setBatch(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              color="secondary"
                              fullWidth
                              error={touched.batch && Boolean(errors.batch)}
                              helperText={touched.batch && errors.batch}
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              variant="button"
                              className={classes.option}
                            >
                              {option}
                            </Typography>
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.label}
                      >
                        {/* Select Board */}
                        Board of the school<br />
                        the student last studied<b style={{ color: "red" }}>*</b>
                      </Typography>
                      <AutoComplete
                        id="boards"
                        name="boards"
                        options={boards_options}
                        value={values.boards}
                        onChange={(e, value) => {
                          setFieldValue("boards", value);
                          setBoards(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            color="secondary"
                            error={touched.boards && Boolean(errors.boards)}
                            helperText={touched.boards && errors.boards}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            variant="button"
                            className={classes.option}
                          >
                            {option}
                          </Typography>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.label}
                      >
                        Father Phone No<b style={{ color: "red" }}>*</b>
                      </Typography>

                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        variant="outlined"
                        color="secondary"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gridGap={10}
                              >
                                <Box>
                                  <img
                                    src={IndFlag}
                                    width="40"
                                    alt="india flag"
                                  />
                                </Box>
                                <Typography variant="button">+91</Typography>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        textAlign="right"
                        className={classes.buttonContainer}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={l1}
                          className={classes.blueGradientBtn}
                        >
                          {l1 ? "Sending OTP..." : "Register"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box textAlign="center" mt={2}>
                        <Typography variant="body2">
                          Already registered up?{" "}
                          <Link
                            to="/reg-login"
                            style={{ textDecoration: "none" }}
                          >
                            Login
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
        <ConfirmationDialog
          open={confirmDialogOpen}
          title="Confirm Details"
          subtitle="Please confirm your details before proceeding."
          handleConfirmClick={handleConfirmDialogConfirm}
          handleCancelClick={handleConfirmDialogClose}
          keyVals={{
            "Branch": branchesData && branch && branchesData[branch] ? branchesData[branch].branchName : "",
            "Seeking Admission": renderOptionText(grade),
            "Board of the school last studied": boards,
            "Father Phone No": phoneNumber,
          }}
        />
      </StyledBox>
    );
  };

  if(paramBranch && (paramBranch === "VSKP" || paramBranch === "RJY" || paramBranch === "BVM")) {
    return (
      <Box
        textAlign="center"
        mt={2}
        style={{
          maxWidth: 400,
          margin: "auto",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="error">
          <b>Registrations for AVAGAHANA SADASSU (2025-2026) are closed</b>
        </Typography>
      </Box>
    );
  }

  if (l1 || l2 || l3) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (paramBranch && branchesList.length > 0 && !isValidBranch) {
    return (
      <Box
        textAlign="center"
        mt={2}
        style={{
          maxWidth: 400,
          margin: "auto",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="error">
          <b>Please check the URL</b>
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="background.default"
        paddingTop={1}
        flexDirection="column"
      >
        {isLargeScreen ? (
          <Box display="flex" width="100%">
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={classes.titleContainer}
            >
              <Typography variant="h2" className={classes.heading}>
                {stuff ? stuff.title : ""}
              </Typography>
              <Typography variant="h2" color="info" className={classes.heading}>
                {title}({academicYear})
              </Typography>
              {countDown && <BoxCountdown countDown={countDown} />}
            </Box>
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {renderForm()}
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridGap={10}
            lineHeight={1}
            padding={1}
          >
            {!otpSent && (
              <Typography variant="h4" color="info" className={classes.heading}>
                {title}({academicYear})
              </Typography>
            )}
            {!otpSent && countDown && <BoxCountdown countDown={countDown} />}
            {renderForm()}
          </Box>
        )}
        <RegFooter />
      </Box>
    </ThemeProvider>
  );
};

export default NewRegistration;
