import React, { useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import store from "store";
import classNames from "classnames";
// mui
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
// common
import { studentNavList, parentNavList } from "../../common/navList";
// component
import NavigationBar from "./navigationBar";
import NotificationPage from "../inAppNotification/Index";
import { Container } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { logoutUser } from "../../apis/UserService";
import AlertComponent from "../AlertComponent";
import clearLocalStorage from "../../common/clearStore";
import logoutAnimation from "../../assets/logout.json";
import LogoutConfirmation from "./LogoutConfirmation";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  myListItemText: {
    fontWeight: "600 !important",
  },
  TopLeftIcons: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // gap: "8px"
  },
  logoutIcon: {
    width: "26px",
    height: "26px",
  },
}));

const Layout = ({ children, value, handleChange }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const isNotMobile = useMediaQuery("(min-width:800px)");
  const user = (store.get("user") ? store.get("user") : {}).accountType;
  const name = (store.get("user") ? store.get("user") : {}).name;
  // const navList = user === "parent" ? parentNavList : studentNavList;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const config = store.get("appSettings") || {};
  const history = useHistory();
  const classes = useStyles();
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setAlertText("");
  };
  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  const logout = async () => {
    // eslint-disable-next-line
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      setConfirmOpen(true);
      setAlertText(
        `Please, Submit Test : ${exam.testDisplayName} Before Logging out!`
      );
    } else {
      const user = store.get("user");
      const _id = user ? user._id : "";
      const phase = user ? user.phase : "";
      await logoutUser({ id: _id });
      clearLocalStorage();
      if (phase && phase === "enquiry") {
        history.push("/reg-login");
      } else {
        history.push("/sign-in");
      }
    }
  };

  const noLayoutRoutes = [
    "/",
    "/sign-in",
    "/register",
    "/exam-analysis",
    "/exam-portal",
    "/instructionadv",
    "/instructionneet",
    "/instruction",
    "/data-sync",
    "/live-zoom-meeting",
    "/new-registration",
    "/student-details",
    "/parent-details",
    "/previous-school-details",
    "/address",
    "/avatar",
    "/review",
    "/reg-login",
    "/reg-dashboard",
    "/enquiry-form",
    "/about-us",
    "/contact-us",
    "/refund-cancellation-policy",
  ];

  const noLayout = useMemo(() => {
    // if (profileEditRoutes.includes(location.pathname)) return true;
    if (location.pathname === "/exam-analysis" && isNotMobile) return false;
    if (location.pathname.includes("/new-registration")) return true;
    return noLayoutRoutes.includes(location.pathname);
  }, [location.pathname, isNotMobile]);

  const applySettings = (navList, settings) => {
    return (
      navList && settings && navList.filter((item) => settings[item.value])
    );
  };

  const userType =
    (store.get("user") &&
      store.get("user") !== undefined &&
      store.get("user").accountType) ||
    "student";

  const navList =
    userType && userType === "parent"
      ? applySettings(parentNavList, config.parentAppSettings)
      : applySettings(studentNavList, config.studentAppSettings);

  if (noLayout) {
    return <div className={classes.mainBackground}>{children}</div>;
  }
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: logoutAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {!isNotMobile ? (
        <>
          <NavigationBar value={value} handleChange={handleChange} />
          <div className={classes.mainBackground}>{children}</div>
        </>
      ) : null}

      {isNotMobile ? (
        <>
          <div className={classes.root}>
            <AppBar
              position="fixed"
              className={classNames({
                [classes.appBar]: true,
                [classes.appBarShift]: false,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={classNames({
                    [classes.menuButton]: open && classes.hide,
                  })}
                >
                  {open ? <ChevronLeftIcon /> : <MenuOpenRoundedIcon />}
                </IconButton>

                <Typography variant="h6" noWrap>
                  {name}
                </Typography>

                <div className={classes.TopLeftIcons}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    className={classNames({
                      [classes.menuButton]: open && classes.hide,
                    })}
                  >
                    <NotificationPage />
                  </IconButton>

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleLogoutDialogOpen}
                    edge="start"
                    className={classNames({
                      [classes.menuButton]: open && classes.hide,
                    })}
                  >
                    <ExitToAppIcon className={classes.logoutIcon} />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>

            {navList && navList.length > 0 && (
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleDrawer}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                <List>
                  {navList.map((item, index) => (
                    <ListItem
                      button
                      key={item.title}
                      onClick={() => history.push(item.path)}
                    >
                      <ListItemIcon>
                        <img src={item.icon} alt={item.title} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        classes={{
                          primary: classes.myListItmeText,
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            )}

            <main
              className={classNames({
                [classes.content]: true,
                [classes.contentShift]: open,
              })}
            >
              <div className={classes.drawerHeader} />
              <Container maxWidth="md">{children}</Container>
            </main>
          </div>
        </>
      ) : null}
      <AlertComponent open={confirmOpen} closeAlert={handleCloseAlertBox}>
        {alertText}
      </AlertComponent>

      <LogoutConfirmation
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        onConfirm={logout}
        lottieOptions={defaultOptions}
      />
    </>
  );
};

export default Layout;
