import React, { useEffect, useMemo, useState, forwardRef } from "react";
import store from "store";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
// mui
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// components
import WrapperCard from "../../mycomponents/HomePage/Components/WrapperCard";
import ContentTypeA from "./contentTypeA";
import TxnCard from "./TxnCard";
import Lottie from "react-lottie";
import failed from "./failed.json";
// assets
import BackIcon from "../../assets/icons/BackIcon";
// hooks
import useFetch from "../../hooks/useFetch";
// apis
import { getStudentFeeInfo } from "../../apis/finance";
// styles
import feeDetailsStyles from "./styles";
// utils
import numberWithCommas from "../../utils/numberWithCommas";
import OnlinePayment from "./OnlinePayment";

const formatter = {
  format: (fig) => `₹${fig && numberWithCommas(fig)}`
};

const summaryKeys = {
  amount: "amt",
  balance: "bal",
  paid: "paid",
  concession: "con"
};

const summaryTitles = {
  amount: "Total Amount",
  balance: "Due",
  paid: "Paid",
  concession: "Concession"
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FeeDetails() {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [ay, setAy] = useState();
  const inst = store.get("user").institute;
  const [detailsVew, setDetailsVew] = useState("");
  const { loginIndex, institute, studentIds } = store.get("user") || {};
  const [isLoading, error, data, fetchData, resetFetch] = useFetch(
    getStudentFeeInfo
  );
  const appSettings = store.get("appSettings") || {};
  const classes = feeDetailsStyles();
  const { currentYear = {}, dueYears = {} } = data || {};
  const isParent = /^[0-9]{10}$/.test(loginIndex) && !!studentIds;
  // const isParent = true;
  const [statusFilter, setStatusFilter] = useState("Ok");

  const handleToggleStatus = () => {
    setStatusFilter((prevStatus) => (prevStatus === "Ok" ? "F" : "Ok"));
  };
  const details_fetched = useMemo(() => {
    return data && data.status === "SUCCESS";
  }, [data, isLoading, error]);

  const error_fetching = useMemo(() => {
    if (!data) return false;
    if (data && data.status === "FAILURE") return data.message;
    return false;
  }, [data, isLoading, error]);

  const ins_current_ay = useMemo(() => {
    if (!data) return "";
    return data.ins_current_ay;
  }, [data]);

  const displayData = useMemo(() => {
    const summary = {
      amt: 0,
      bal: 0,
      con: 0,
      paid: 0
    };

    if (current === 0) {
      const rows = currentYear.rows || [];
      for (const item of rows) {
        const { amount, balance, concession, paid } = item;
        summary.amt = summary.amt + amount;
        summary.bal = summary.bal + balance;
        summary.con = summary.con + concession;
        summary.paid = summary.paid + paid;
      }
      return { summary, rows, history: currentYear.history };
    } else if (ay && current === 1) {
      const stuff = dueYears[ay];
      const rows = stuff ? stuff.rows : [];

      for (const item of rows) {
        const { amount, balance, concession, paid } = item;
        summary.amt = summary.amt + amount;
        summary.bal = summary.bal + balance;
        summary.con = summary.con + concession;
        summary.paid = summary.paid + paid;
      }
      return { summary, rows, history: stuff.history };
    }

    return { summary, rows: [], history: [] };
  }, [currentYear, dueYears, current, ay]);

  const dueYearCodes = useMemo(() => {
    return Object.keys(dueYears ? dueYears : {});
  }, [dueYears]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (open) {
      setDetailsVew(anchor);
    } else {
      setDetailsVew("");
    }
  };

  useEffect(() => {
    if (dueYearCodes.length > 0) {
      setAy(dueYearCodes[0]);
    }
  }, [dueYearCodes]);

  useEffect(() => {
    if (isParent) {
      const body = {
        admissionNo: `${loginIndex}`,
        instituteId: institute,
        studentId: studentIds

        // admissionNo: "9347050516",
        // instituteId: "646634c8a400ecffc1306d0d",
        // studentId: "646cad9188be64127bebe1a0",
      };

      fetchData(body);
    }

    return () => {
      resetFetch();
    };
  }, []);

  const student = appSettings && appSettings.studentAppSettings;
  const parent = appSettings && appSettings.parentAppSettings;

  const renderDivider = ({ key, summaryKey, title, autoHeight = false }) => {
    if (!key) return null;

    return (
      <div
        className={classNames({
          [classes.detailsViewContainer]: true,
          [classes.removePrev]: !!autoHeight
        })}
      >
        <div
          className={classNames({
            [classes.drawerTitleContainer]: true,
            [classes.autoHeight]: !!autoHeight
          })}
        >
          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.drawerTitle}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            color="primary"
            className={classes.drawerTitle}
          >
            {formatter.format(displayData.summary[summaryKey])}
          </Typography>
        </div>
        {displayData.rows.map((item, i) => {
          const { feeType } = item;
          return (
            <div className={classes.descTitleContainer} key={`q-${i}`}>
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.descFeeName}
              >
                {feeType}
              </Typography>
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.descFeeValue}
              >
                {formatter.format(item[key])}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={classes.container}>
        <div className={classes.headContainer}>
          <IconButton onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <div>
            <Typography className={classes.title}>Fee Details</Typography>
          </div>
        </div>
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (error_fetching || error) {
    return (
      <div className={classes.container}>
        <div className={classes.headContainer}>
          <IconButton onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <div>
            <Typography className={classes.title}>Fee Details</Typography>
          </div>
        </div>
        <div>
          <Typography
            color="error"
            variant="h6"
            style={{
              width: "100%",
              textAlign: "center"
            }}
          >
            Error fetching details
          </Typography>
        </div>
      </div>
    );
  }

  if (details_fetched && isParent) {
    return (
      <div className={classes.container}>
        <div className={classes.headContainer}>
          <IconButton onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <div>
            <Typography className={classes.title}>Fee Details</Typography>
          </div>
        </div>
        <div className={classes.subTitleContainer}>
          <Typography
            variant="caption"
            className={classes.subHeading}
            role="button"
            onClick={() => setCurrent(0)}
            color={current === 0 ? "primary" : "textPrimary"}
          >
            Current Ay {ins_current_ay}&nbsp;
          </Typography>
          <div className={classes.seperator}>|</div>
          <Typography
            variant="caption"
            className={classes.subHeading}
            role="button"
            onClick={() => setCurrent(1)}
            color={current === 1 ? "primary" : "textPrimary"}
          >
            &nbsp;Previous Ay Dues
          </Typography>
        </div>
        {dueYearCodes.length > 0 && current === 1 ? (
          <div className={classes.subTitleContainer}>
            <div className={classes.buttonsContainer}>
              {dueYearCodes.map((y, i) => (
                <>
                  <Button
                    key={`${i}`}
                    variant="contained"
                    size="small"
                    color={ay === y ? "primary" : ""}
                    className={
                      ay === y ? classes.activeBtn : classes.inActiveBtn
                    }
                    onClick={() => setAy(y)}
                  >
                    {y}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                </>
              ))}
            </div>
          </div>
        ) : null}
        {current === 0 || (current === 1 && !!ay) ? (
          <>
            <div className={classes.maninContentContainer}>
              <WrapperCard>
                <div style={{ width: "100%" }}>
                  <ContentTypeA
                    itemA={{
                      name: "Student Name",
                      value: `${data.fullName ? data.fullName : ""} ${
                        data.lastName ? data.lastName : ""
                      }`,
                      valueColor: "primary"
                    }}
                    itemB={{
                      name: "Admission No",
                      value: `${data.admissionNo ? data.admissionNo : ""} `,
                      valueColor: "primary"
                    }}
                  />
                  <Divider className={classes.mainDivider} />
                  {(student.viewActualAmount || parent.viewActualAmount) && (
                    <>
                      <ContentTypeA
                        itemA={{
                          name: "Actual Amount",
                          value: `${formatter.format(displayData.summary.amt)}`,
                          valueColor: "textPrimary"
                        }}
                        bIsBtn={true}
                        handleClick={toggleDrawer("amount", true)}
                      />
                      <Divider className={classes.mainDivider} />
                    </>
                  )}
                  {(student.viewConcession || parent.viewConcession) && (
                    <>
                      <ContentTypeA
                        itemA={{
                          name: "Concession",
                          value: `${formatter.format(displayData.summary.con)}`,
                          valueColor: "success"
                        }}
                        bIsBtn={true}
                        handleClick={toggleDrawer("concession", true)}
                      />
                      <Divider className={classes.mainDivider} />
                    </>
                  )}
                  {(student.viewPaid || parent.viewPaid) && (
                    <>
                      <ContentTypeA
                        itemA={{
                          name: "Paid",
                          value: `${formatter.format(
                            displayData.summary.paid
                          )}`,
                          valueColor: "success"
                        }}
                        bIsBtn={true}
                        isIcon={true}
                        handleClick={toggleDrawer("paid", true)}
                      />
                      <Divider className={classes.mainDivider} />
                    </>
                  )}
                  {(student.viewDueAmount || parent.viewDueAmount) && (
                    <ContentTypeA
                      itemA={{
                        name: "Due",
                        value: `${formatter.format(displayData.summary.bal)}`,
                        valueColor: "secondary"
                      }}
                      bIsBtn={true}
                      handleClick={toggleDrawer("balance", true)}
                    />
                  )}
                </div>
              </WrapperCard>
              &nbsp;
              {inst === "5ea3572fe301133d0a73e9e0" &&
                displayData.summary.bal > 0 && (
                  <div style={{ marginBottom: "100px" }}>
                    <WrapperCard>
                      <OnlinePayment data={data} />
                    </WrapperCard>
                  </div>
                )}
              &nbsp; &nbsp; &nbsp; &nbsp;
            </div>
            &nbsp; &nbsp;
            {detailsVew === "paid" ? (
              <Dialog
                fullScreen
                open={!!detailsVew}
                onClose={toggleDrawer(detailsVew, false)}
                TransitionComponent={Transition}
                className={classes.dialog}
                classes={{
                  paper: classes.dialogPaper
                }}
              >
                <AppBar className={classes.appBar} elevation={0}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={toggleDrawer(detailsVew, false)}
                      aria-label="close"
                    >
                      <BackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.pageTitle}>
                      Fee Paid Details
                    </Typography>
                    {statusFilter === "Ok" ? (
                      <IconButton
                        edge="start"
                        color="error"
                        onClick={handleToggleStatus}
                        aria-label="close"
                      >
                        <SmsFailedIcon color="error" />
                      </IconButton>
                    ) : (
                      displayData.history.filter(
                        (txn) => txn.status === statusFilter
                      ).length > 0 && (
                        <IconButton
                          edge="start"
                          color="error"
                          onClick={handleToggleStatus}
                          aria-label="close"
                        >
                          <CheckCircleIcon color="primary" />
                        </IconButton>
                      )
                    )}

                    {/* <Button
                      variant="text"
                      onClick={handleToggleStatus}
                      style={{
                        marginLeft: "auto",
                        color: statusFilter === "F" ? "#4daf50" : "#F54458"
                      }}
                    >
                      {statusFilter === "Ok"
                        ? " View Failed Transactions"
                        : " View Successful Transactions"}
                    </Button> */}
                  </Toolbar>
                </AppBar>

                {detailsVew ? (
                  <div className={classes.maninContentContainer}>
                    <div className={classes.paidDetailsContainer}>
                      <WrapperCard>
                        <div style={{ width: "100%" }}>
                          {renderDivider({
                            key: detailsVew,
                            summaryKey: summaryKeys[detailsVew],
                            title: summaryTitles[detailsVew],
                            autoHeight: true
                          })}
                        </div>
                      </WrapperCard>
                    </div>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "20px" }}
                    >
                      {displayData.history
                        .filter((txn) => txn.status === statusFilter)
                        .map((txn) => (
                          <Grid item xs={12} md={6} lg={4} key={txn.id}>
                            <TxnCard txn={txn} />
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                ) : null}
              </Dialog>
            ) : (
              // <Dialog
              //   fullScreen
              //   open={!!detailsVew}
              //   onClose={toggleDrawer(detailsVew, false)}
              //   TransitionComponent={Transition}
              //   className={classes.dialog}
              //   classes={{
              //     paper: classes.dialogPaper
              //   }}
              // >
              //   <AppBar className={classes.appBar} elevation={0}>
              //     <Toolbar>
              //       <IconButton
              //         edge="start"
              //         color="inherit"
              //         onClick={toggleDrawer(detailsVew, false)}
              //         aria-label="close"
              //       >
              //         <BackIcon />
              //       </IconButton>
              //       <Typography variant="h6" className={classes.pageTitle}>
              //         Fee Paid Details
              //       </Typography>
              //     </Toolbar>
              //   </AppBar>

              //   {detailsVew ? (
              //     <div className={classes.maninContentContainer}>
              //       <div className={classes.paidDetailsContainer}>
              //         <WrapperCard>
              //           <div style={{ width: "100%" }}>
              //             {renderDivider({
              //               key: detailsVew,
              //               summaryKey: summaryKeys[detailsVew],
              //               title: summaryTitles[detailsVew],
              //               autoHeight: true
              //             })}
              //           </div>
              //         </WrapperCard>
              //       </div>
              //       <Grid
              //         container
              //         spacing={2}
              //         style={{ marginBottom: "20px" }}
              //       >
              //         {displayData.history
              //           .filter((txn) => txn.status === "Ok")
              //           .map((txn) => (
              //             <Grid item xs={12} md={6} lg={4} key={txn.id}>
              //               <TxnCard txn={txn} />
              //             </Grid>
              //           ))}
              //         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              //         &nbsp;
              //       </Grid>
              //     </div>
              //   ) : null}
              // </Dialog>
              <Drawer
                anchor={"bottom"}
                open={!!detailsVew}
                onClose={toggleDrawer(detailsVew, false)}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {detailsVew
                  ? renderDivider({
                      key: detailsVew,
                      summaryKey: summaryKeys[detailsVew],
                      title: summaryTitles[detailsVew]
                    })
                  : null}
              </Drawer>
            )}
          </>
        ) : null}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headContainer}>
        <IconButton onClick={() => history.goBack()}>
          <BackIcon />
        </IconButton>
        <div>
          <Typography className={classes.title}>Fee Details</Typography>
        </div>
      </div>
      <div>
        <Typography variant="body1">Data not available</Typography>
      </div>
    </div>
  );
}

{
  /* {renderAccordion({
              title: "Total Amount",
              key: "amount",
              summaryKey: "amt",
            })}
            {renderAccordion({
              title: "Concession",
              key: "concession",
              summaryKey: "con",
            })}
            {renderAccordion({
              title: "Paid",
              key: "paid",
              summaryKey: "paid",
            })}
            {renderAccordion({
              title: "Balance",
              key: "balance",
              summaryKey: "bal",
            })} */
}

// const renderAccordion = ({ title, key, summaryKey }) => {
//   return (
//     <div className={classes.maninContentContainer}>
//       <Accordion
//         style={{ borderRadius: "16px" }}
//         className={`${classes.accordion}`}
//       >
//         <AccordionSummary expandIcon={<ExpandIcon />}>
//           <div className={classes.descTitleContainer}>
//             <Typography
//               variant="caption"
//               className={classes.subHeading}
//               color="textPrimary"
//             >
//               {title}
//             </Typography>
//             <Typography
//               variant="caption"
//               className={classes.subHeading}
//               color="secondary"
//             >
//               {formatter.format(displayData.summary[summaryKey])}
//             </Typography>
//           </div>
//         </AccordionSummary>
//         <AccordionDetails>
//           <div style={{ width: "100%" }}>
//             {displayData.rows.map((item, i) => {
//               const { feeType } = item;
//               return (
//                 <div className={classes.descTitleContainer} key={`q-${i}`}>
//                   <Typography
//                     variant="caption"
//                     color="textPrimary"
//                     className={classes.descFeeName}
//                   >
//                     {feeType}
//                   </Typography>
//                   <Typography
//                     variant="caption"
//                     color="textPrimary"
//                     className={classes.descFeeValue}
//                   >
//                     {formatter.format(item[key])}
//                   </Typography>
//                 </div>
//               );
//             })}
//           </div>
//         </AccordionDetails>
//       </Accordion>
//     </div>
//   );
// };
