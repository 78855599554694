import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import colors from "../../assets/v3/base/colors";
import InfoIcon from "@material-ui/icons/Info";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import RefundIcon from "@material-ui/icons/MonetizationOn";
import CallIcon from "@material-ui/icons/Call";
import PolicyIcon from "@material-ui/icons/Poll";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: colors.dark.main,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    flexGrow: 1,
    marginTop: theme.spacing(4),
    maxHeight: "200px", // Add this line to set the max height
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  link: {
    margin: theme.spacing(0.5, 0),
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RegFooter = () => {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Box className={classes.container}>
        <Box className={classes.column}>
          <Typography variant="body2" className={classes.link}>
            <InfoIcon className={classes.icon} />
            <a
              href="/about-us"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              About Us
            </a>
          </Typography>
          <Typography variant="body2" className={classes.link}>
            <CallIcon className={classes.icon} />
            <a
              href="/contact-us"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Contact Us
            </a>
          </Typography>
          <Typography variant="body2" className={classes.link}>
            <PolicyIcon className={classes.icon} />
            <a
              href="/refund-cancellation-policy"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Refund/Cancellation Policy
            </a>
          </Typography>
        </Box>
        <Box className={classes.column}>
          <Typography variant="body2" className={classes.link}>
            <PrivacyPolicy />
          </Typography>
          <Typography variant="body2" className={classes.link}>
            <TermsAndConditions />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RegFooter;
