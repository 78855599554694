import axios from "./examServerAxios";
import store from "store";
import { adminAxios } from "./baseAxios";

export async function userLogin(body) {
  try {
    let data = await axios.post("/users/login", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function parentLoginApi(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/parentLogin", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    console.log("hello");
    return error;
  }
}

export async function sendOTP(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/createotp", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    return error;
  }
}

export async function userRegister(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/registerg", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    return error;
  }
}

export async function changePassword(body) {
  try {
    let data = await axios.post("/users/forgotpassword", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function editUserProfile(body) {
  try {
    let data = await axios.post(`/users/editprofile/${body.userId}`, body.body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function domainConfig(host) {
  try {
    let data = await axios.get(
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/domain_config/" +
        host +
        ".json"
    );
    return data;
  } catch (error) {
    return error;
  }
}

// get time slots for all india exams
export async function allIndiaExamTimeSlots() {
  try {
    let data = await axios.get("/allIndiaTestSlots");
    return data;
  } catch (error) {
    return error;
  }
}
export async function logoutUser(payload) {
  const user = store.get("user");
  try {
    if (navigator.userAgent.includes("Android") && window.Android) {
      window.Android.clearFCMToken(`${payload.id}`);
    }
    let data = await axios.post("/users/logout", payload);
    if (user && user.level && user.level === "X0210780") {
      window.location.href = "https://miityedu.in";
    }
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getUser(id) {
  try {
    const data = await axios.get(`/users/${id}`);
    return data.data;
  } catch (error) {
    throw error;
  }
}

export async function changeFirstLogin(payload) {
  try {
    let data = await axios.post("/users/changeFirstLogin", payload);
    return data.data;
  } catch (error) {
    return error;
  }
}
export async function getStudentForm(body) {
  try {
    return (await adminAxios.get("/parent-portal/doc", { params: { ...body } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function newRegister(body) {
  try {
    let data = await axios.post("/users/register", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getRegistrationFormConfig(params) {
  try {
    const data = await axios.get("/users/reg-form-config", { params });
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function updateStudentDetails(body) {
  try {
    let data = await axios.post("/users/update-details", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function uploadAvatar(data) {
  const { sid, avatar } = data;
  const formData = new FormData();
  if (avatar) {
    formData.append("avatar", avatar);
  }

  try {
    let data = await axios.post(`/users/avatar/${sid}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error;
  }
}

export async function newRegLogin(body) {
  try {
    let data = await axios.post("/users/reg-login", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function insertEnquiry(body) {
  try {
    let data = await axios.post("/users/insertEnquiry", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function createOtpLogin(body) {
  try {
    let data = await axios.post("users/create-otp-login", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function createOtpRegister(body) {
  try {
    let data = await axios.post("users/create-otp-register", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getHallTicket({ sid, mode }) {
  try {
    const { data } = await adminAxios.get("/parent-portal/tt-hall-ticket", {
      params: { sid, mode },
    });
    if (mode === "view") {
      return data;
    }
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `document.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return { message: "SUCCESS" };
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function generateUserTalentTestHallTicket(body) {
  try {
    let data = await axios.post("/users/generate-tt-hall-ticket", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStudentCommittedFee(params) {
  try {
    const data = await axios.get("/users/committed-fee", { params });
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function agreeToFee({ sid, signature }) {
  const formData = new FormData();
  formData.append("signature", signature);
  formData.append("sid", sid);

  try {
    const data = await axios.post("/users/agree-to-fee", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function updateProfileProgress(body) {
  try {
    let data = await axios.post("/users/update-profile-progress", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function updateTransactionStatuses(body) {
  try {
    let data = await axios.post("/users/update-txn-statuses", body);
    return data;
  } catch (error) {
    return error;
  }
}
