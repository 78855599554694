import React from "react";
import store from "store";
// mui
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useFeeData from "../Dashboard/Components/useStudentData";
import NotificationPage from "../inAppNotification/Index";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    height: "60px",
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 0,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
  root: {
    display: "flex",
    paddingRight: theme.spacing(2),
    alignItems: "center",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: "1px solid #E0E0E0",
  },
  helloText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#2A2A2A",
    flex: "none",
    order: 0,
    flexGrow: 0,
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px", // Desktop
    },
  },
  nameBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    // width: '100%',
    // height: '100%',
    order: 1,
    flexGrow: 0,
    justifyContent: "flex-start",
    alignContent: "flex-start",
    textAlign: "flex-start",
    marginLeft: theme.spacing(1),
  },
  nameText: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2A2A2A",
    display: "flex",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 1,
    textAlign: "flex-start",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px", // desktop
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px", // tablet
    },
  },
  iconButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    background: "#EBEFF3",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
}));

export default function BadgeAvatars() {
  const classes = useStyles();
  const user = store.get("user")&&store.get("user").name || store.get("user").fullName;
  const [, , , avatar] = useFeeData();

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.avatarContainer}>
          <Avatar alt="Remy Sharp" src={avatar} className={classes.large} />
        </div>
        <div className={classes.nameBox}>
          <div className={classes.helloText}>Hello,</div>
          <Tooltip title={user}>
            <div className={classes.nameText}>
              <b>{user && user.split(" ")[0]}</b>
            </div>
          </Tooltip>
        </div>
      </div>
      <NotificationPage />
    </div>
  );
}
