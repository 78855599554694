import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import store from "store";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";

// Material-UI components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Theme and styles
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../assets/v3/theme";
import colors from "../../assets/v3/base/colors";
import { useStyles } from "./ReviewPage";

// API services
import {
  getRegistrationFormConfig,
  getHallTicket,
  generateUserTalentTestHallTicket,
} from "../../apis/UserService";
import { initiateGateWayTxn } from "../../apis/payService";

// Custom hooks and context
import useFetch from "../../hooks/useFetch";
import { useSnackbar } from "../../context/snackbar";

// Utility functions and constants
import {
  StudentDetailsFields,
  ParentDetailsFields,
  PreviousSchoolDetails,
  AddressFieldDetails,
} from "./utils";
import { hostnamesMap } from "../../components/LogoURLMap";

// Custom components
import StyledBox from "../../components/StyledBox";
import TextScroll from "./TextScroll";
import CheckoutTable from "../../components/Checkout";
import LogoutConfirmation from "../../mycomponents/HomePage/LogoutConfirmation";
import logoutAnimation from "../../assets/logout.json";

const RegDashBoard = () => {
  const classes = useStyles();
  const { openSnackbar } = useSnackbar();
  const instituteId = hostnamesMap[window.location.hostname];
  const sid = store.get("user")._id;
  const [l1, e1, d1, f1, r1] = useFetch(getRegistrationFormConfig);
  const [l2, e2, d2, f2, r2] = useFetch(getHallTicket);
  const [l3, e3, d3, f3, r3] = useFetch(generateUserTalentTestHallTicket);
  const [l4, e4, d4, f4, r4] = useFetch(initiateGateWayTxn);
  const [details, setDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const payload = d1 ? d1.payload : null;
  const statuses = d1 ? d1.statuses : null;
  const test_name = d1 ? d1.test_name : "";
  const fee_title = payload ? payload.fee_title : "";
  const display_text = payload ? payload.display_text : "";
  const scrollText =
    payload && payload.scroll_text ? payload.scroll_text : null;
  // statuses
  const paymentStatus = statuses ? statuses.paymentStatus : "";
  const seatAllotmentStatus = statuses ? statuses.seatAllotmentStatus : "";
  const amount = statuses ? statuses.amount : "";
  const gstAmount = statuses ? statuses.gstAmount : "";
  const totalAmount = statuses ? statuses.totalAmount : "";
  const feeRecordFound = statuses ? statuses.feeRecordFound : false;
  const generated = feeRecordFound && seatAllotmentStatus === "ALLOTTED";
  const search = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(search.entries());
  const history = useHistory();

  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  const fieldMap = useMemo(() => {
    return [
      ...StudentDetailsFields,
      ...ParentDetailsFields,
      ...PreviousSchoolDetails,
      ...AddressFieldDetails,
    ].reduce((map, field) => {
      map[field.field] = field;
      return map;
    }, {});
  }, []);

  const initialValues = useMemo(() => {
    return Object.keys(fieldMap).reduce((values, key) => {
      const myKey = fieldMap[key].key;
      if (myKey && details) {
        values[key] = myKey.includes(".")
          ? details[myKey.split(".")[0]]
            ? details[myKey.split(".")[0]][myKey.split(".")[1]]
            : ""
          : details[myKey];

        if (myKey === "dateOfBirth" && values[key]) {
          values[key] = moment(values[key]).format("YYYY-MM-DD");
        }
      } else {
        values[key] = "";
      }
      return values;
    }, {});
  }, [fieldMap, details]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (instituteId && sid) {
      f1({ instituteId, page: "reg-dashboard", sid });
    }

    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      setDetails(d1.details);
      if (
        paymentStatus === "PAID" &&
        seatAllotmentStatus !== "ALLOTTED" &&
        sid &&
        test_name
      ) {
        if (searchParams && searchParams.status === "Ok") {
          openSnackbar("Payment successful", "success", false, {
            customActions: [
              {
                text: "Generate Entry Form",
                onClick: () => f3({ sid, test_name }),
                className: "success",
                closeOnClick: true,
              },
            ],
            // showCloseIcon: true,
            actionsPosition: "center",
            onClose: () => f3({ sid, test_name }),
            autoClose: true,
            triggerType: "auto",
          });
        } else {
          openSnackbar("Click To Generate Entry Form", "success", false, {
            customActions: [
              {
                text: "Generate Entry Form",
                onClick: () => f3({ sid, test_name }),
                className: "success",
                closeOnClick: true,
              },
            ],
            // showCloseIcon: true,
            actionsPosition: "center",
            onClose: () => f3({ sid, test_name }),
            autoClose: true,
            triggerType: "auto",
          });
        }
      }
      if (generated) {
        f2({ sid, mode: "view" });
      }

      if (searchParams.status === "F") {
        openSnackbar(searchParams.message, "warning", false);
      }
    }
    if (e1) {
      console.error("Failed to fetch registration details:", e1);
      openSnackbar("Failed to fetch registration details", "error");
    }
  }, [d1, e1]);

  useEffect(() => {
    if (d2) {
      if (d2.html) {
        setHtmlContent(d2.html);
      } else {
        openSnackbar("downloaded successfully", "success");
      }
    }
    if (e2) {
      openSnackbar("Failed to download", "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2]);

  useEffect(() => {
    if (d3) {
      if (d3.data.status === "SUCCESS") {
        f1({ instituteId, page: "reg-dashboard", sid });
      } else if (d3.data.status === "FAILURE") {
        openSnackbar(d3.data.message, "error", false);
      }
    }
    if (e3) {
      openSnackbar("Failed to generate hall ticket", "error");
    }

    return () => {
      r3();
    };
  }, [d3, e3]);

  useEffect(() => {
    if (d4) {
      if (d4.data && d4.data.status === "SUCCESS") {
        // openSnackbar("Payment initiated successfully", "success");

        const formContainer = document.createElement("div");
        formContainer.innerHTML = d4.data.html;

        // Append to body temporarily
        document.body.appendChild(formContainer);

        // Submit the form
        const form = formContainer.querySelector("form");
        if (form) {
          form.submit();
        }
        // Handle successful payment initiation
      } else if (d4.data.status === "FAILURE") {
        openSnackbar(d4.data.message, "error", false);
      }
    }
    if (e4) {
      openSnackbar("Failed to initiate payment", "error");
    }

    return () => {
      r4();
    };
  }, [d4, e4]);

  const handleDownloadHallTicket = () => {
    f2({ sid });
  };

  const handleAvatarClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePayClick = (provider) => {
    if (!provider) {
      openSnackbar("Please select a payment service", "warning");
      return;
    }
    if (sid && provider && test_name) {
      f4({ sid, test_name, provider });
    }
  };

  const logout = () => {
    store.clearAll();
    history.push("/reg-login");
  };

  const renderDetails = (title, fields) => (
    <StyledBox
      // shadow={"lg"}
      borderRadius="lg"
      marginTop={0.5}
      padding={2}
      bgColor="white"
    >
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field) => {
          const value = initialValues[field.field] || "";
          if (!value) return null;
          return (
            <Grid item xs={12} key={field.label}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.key}
                >
                  {field.label}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.value}
                  color="primary"
                >
                  :&nbsp;{value}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </StyledBox>
  );

  const amounts = [
    { title: "Amount", value: amount },
    { title: "GST", value: gstAmount },
    // Add more items as needed
  ];

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: logoutAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (l1 || l2 || l3) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {scrollText && (
        <TextScroll
          text={scrollText}
          textStyles={{
            fontWeight: "bolder",
            fontSize: "1.5rem",
            background: `-webkit-linear-gradient(#ED1B24, #074EA0)`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        />
      )}
      <Box className={classes.mainContainer} minHeight="100vh">
        <Container maxWidth="md">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
          >
            <Avatar
              alt={details && details.fullName ? details.fullName : "Avatar"}
              src={`${details && details.avatar ? details.avatar : ""}`}
              className={classes.large}
              onClick={handleAvatarClick}
              style={{ cursor: "pointer" }}
            />

            <Button
              color="inherit"
              aria-label="open drawer"
              onClick={handleLogoutDialogOpen}
              edge="start"
            >
              <ExitToAppIcon className={classes.logoutIcon} /> &nbsp; Logout
            </Button>
          </Box>
          {htmlContent && generated && paymentStatus === "PAID" && (
            <>
              <Box marginTop={2} marginLeft={2}>
                <Button
                  onClick={handleDownloadHallTicket}
                  disabled={l2}
                  color="primary"
                  startIcon={<PictureAsPdfIcon />}
                  variant="outlined"
                >
                  {l2 ? "Downloading..." : "Download"}
                </Button>
                <br />
                <br />
                <Typography variant="body2" color="textPrimary">
                  <span>Note*: </span>
                  <b style={{ color: "red" }}>
                    Please take a print of the entry form to attend Avagahana
                    Sadassu
                  </b>
                </Typography>
              </Box>
              <Box marginTop={1}>
                <iframe
                  srcDoc={htmlContent}
                  title="Hall Ticket"
                  width="100%"
                  style={{ border: "none", minHeight: "80vh" }}
                />
              </Box>
            </>
          )}
          {feeRecordFound && paymentStatus !== "PAID" && (
            <Box marginTop={2} bgColor="background.default">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      variant="body1"
                      style={{
                        color: colors.dark.main,
                      }}
                    >
                      {display_text}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontWeight: 300 }}
                    >
                      Note: Fee once paid will not be refunded under any
                      circumstances.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      className={classes.white}
                      p={2}
                      maxWidth={500}
                      minWidth={300}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      gridGap={10}
                      flexGrow={1}
                    >
                      <CheckoutTable
                        fee_title={fee_title}
                        amounts={amounts}
                        totalAmount={totalAmount}
                        classes={classes}
                        btnClass={classes.blueGradientBtn}
                        handlePayClick={handlePayClick}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Details
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {renderDetails("Student Details", StudentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Parent Details", ParentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Previous School Details", PreviousSchoolDetails)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Address Details", AddressFieldDetails)}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <LogoutConfirmation
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        onConfirm={logout}
        lottieOptions={defaultOptions}
      />
    </ThemeProvider>
  );
};

export default RegDashBoard;
