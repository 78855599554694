import React, { useEffect, useState } from "react";
import store from "store";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  TextField,
  Divider,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { Save as SaveIcon, Cancel as CancelIcon } from "@material-ui/icons";
import logoURLMap from "../../../components/LogoURLMap";
import ReusableAccordion from "../Components/ReusableAccordion";
import useStudentData from "../../Dashboard/Components/useStudentData";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import ResultHeader from "../../resultPage/ResultHeader";
import EditSvgIcon from "../../../assets/img/navigationBarAssets/editIcon.svg";
import PrintEnquiryProfile from "../../../mycomponents/PrintEnquiryProfile";
import Button from "../../../components/CustomButtons/Button";
import restrictedLevel from "../../../views/SignIn/restrictedLevel";
import { getUser } from "../../../apis/UserService";
import useFetch from "../../../hooks/useFetch";
import { getStudentForm } from "../../../apis/UserService";
import { sub } from "date-fns";

const useStyles = makeStyles({
  table: {
    border: "none"
  },
  title: {
    color: "#2A2A2A",
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Quicksand",
    padding: "16px 0"
  },
  icon: {
    fontSize: "25px",
    fontWeight: 700,
    color: "#2A2A2A"
  },
  keyTableCell: {
    padding: "8px 16px",
    borderBottom: "none",
    color: "#2A2A2A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Quicksand"
    // width: "400px"
  },
  valueTableCell: {
    padding: "8px 16px",
    borderBottom: "none",
    color: "#2A2A2A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Quicksand"
  }
});

export default function Profile({ hideTitle }) {
  const classes = useStyles();
  const userDetail = store.get("user") || {};
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState({});
  const [studentAdmissionNo, omrNo, stuFeeData, avatar] = useStudentData();
  const [l, e, response, getUserData, reset] = useFetch(getUser);
  const [, error, pdf, get, resetTc] = useFetch(getStudentForm);
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const handleClose = () => setOpenPrintDialog(false);
  const [details, setDetails] = useState({});
  useEffect(() => {
    getUserData(userDetail._id);
  }, []);

  useEffect(() => {
    if (response) {
      setData(response);
    }
  }, [response]);

  useEffect(() => {
    setDetails(stuFeeData);
  }, [stuFeeData]);
  const [personalDetails, setPersonalDetails] = useState({});
  const [address, setAddress] = useState({});
  useEffect(() => {
    if (details.student) {
      if (details.student.personalDetails) {
        setPersonalDetails(details.student.personalDetails);
      }
      if (details.student.address) {
        setAddress(details.student.address);
      }
    }
  }, [details]);

  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [count, setCount] = useState(0);
  const handleChange = e => {
    const { name, value } = e.target;
    setEditableData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  const formatAddress = address => {
    if (typeof address === "object" && address !== null) {
      return Object.entries(address)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
    }

    // If address is not an object, return it as a string
    return address ? String(address) : "";
  };
  const handleEdit = () => {
    setIsEditing(true);
    setCount(1);
    setEditableData({
      fatherName: personalDetails.fatherName || "",
      fatherNumber: personalDetails.fatherNumber || "",
      fatherEmail: personalDetails.fatherEmail || "",
      // fatherProfession: personalDetails.fatherProfession || "",
      fatherBloodGroup: personalDetails.fatherBloodGroup || "",
      motherName: personalDetails.motherName || "",
      fatherOccupation: personalDetails.fatherOccupation || "",
      motherMobile: personalDetails.motherMobile || "",
      motherEmail: personalDetails.motherEmail || "",
      // motherProfession: personalDetails.motherProfession || "",
      motherOccupation: personalDetails.motherOccupation || "",
      motherBloodGroup: personalDetails.motherBloodGroup || "",
      address: formatAddress(address) || {}
    });
  };

  const handleSave = () => {
    setIsEditing(false);
    setCount(0);
    // Save the updated data to your server or state management
    setPersonalDetails(prevDetails => ({
      ...prevDetails,
      ...editableData
    }));
    setAddress(editableData.address);
  };
  console.log(editableData);
  const handleCancel = () => {
    setIsEditing(false);
    setCount(0);
    setEditableData({});
  };

  useEffect(() => {
    if (pdf) {
      const byteCharacters = atob(pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${data.registrationNo || data.fullName}_enquiryForm.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      // enqueueSnackbar("Download successfully", {
      //   variant: "success",
      //   autoHideDuration: 1500,
      // });
    }
  }, [pdf]);

  const renderStudentSummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.title}>
        Student Profile
      </Grid>
    </Grid>
  );

  const renderParentSummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={8} className={classes.title}>
        Parent Profile
      </Grid>
      {restrictedLevel.includes(
        `${userDetail.level || userDetail.batch}`
      ) ? null : (
        <Grid item xs={4} style={{ textAlign: "right" }}>
          {isEditing ? (
            <>
              <IconButton onClick={handleSave} color="primary" size="small">
                <SaveIcon />
              </IconButton>
              <IconButton onClick={handleCancel} color="secondary" size="small">
                <CancelIcon />
              </IconButton>
            </>
          ) : (
            // <IconButton color="primary" size="small">
            <img src={EditSvgIcon} alt="Edit" onClick={handleEdit} />
            // </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );

  const renderStudentDetails = () => {
    const studentData = {
      "Reg. No.": stuFeeData.student.registrationNo || "-",
      name:
        `${stuFeeData.student.fullName} ${stuFeeData.student.lastName}` || "-",
      class: stuFeeData.student.class || "-",
      userId: store.get("user").userId || store.get("user").admissionNo || "-",
      batch: stuFeeData.batchName || "-",
      bloodGroup:
        (stuFeeData.student.additionalInfo &&
          stuFeeData.student.additionalInfo.bloodGroup) ||
        "-",
      alternateNumber:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.alternateMobileNo) ||
        "-",
      email:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherEmail) ||
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.studentEmail) ||
        "-",
      fatherName:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherName) ||
        "-",
      motherName:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.motherName) ||
        "-",
      whatsappNumber:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherNumber) ||
        store.get("user").userId ||
        store.get("user").admissionNo ||
        "-"
    };

    return (
      stuFeeData.student && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer className={classes.table}>
              <Table>
                <TableBody>
                  {Object.entries(studentData).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.keyTableCell}
                      >
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, str => str.toUpperCase())}
                      </TableCell>
                      <TableCell className={classes.valueTableCell}>
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )
    );
  };

  const renderParentDetails = () => {
    const sections = {
      FatherDetails: {
        fatherName: personalDetails.fatherName || "-",
        fatherNumber: personalDetails.fatherNumber || "-",
        fatherEmail:
          personalDetails.fatherEmail || personalDetails.studentEmail || "-",
        // fatherProfession: personalDetails.fatherProfession || "-",
        fatherBloodGroup: personalDetails.fatherBloodGroup || "-",
        fatherOccupation: personalDetails.fatherOccupation || "-"
      },
      MotherDetails: {
        motherName: personalDetails.motherName || "-",
        motherMobile: personalDetails.motherMobile || "-",
        motherEmail: personalDetails.motherEmail || "-",
        // motherProfession: personalDetails.motherProfession || "-",
        motherBloodGroup: personalDetails.motherBloodGroup || "-",
        motherOccupation: personalDetails.motherOccupation || "-"
      },
      Address:{
      city: address.city || "-",
      district: address.district || "-",
      houseNo: address.houseNo || "-",
      pincode: address.pincode || "-",
      state: address.state || "-",
      street: address.street || "-",
      village: address.village || "-"
      }
    
      // Address: {
      //   address: Object.values(address) || address || ""
      // }
    };

    return (
      Object.keys(stuFeeData.student.personalDetails || {}).length !== 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer className={classes.table}>
              <Table>
                <TableBody>
                  {Object.entries(sections.FatherDetails).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.keyTableCell}
                        >
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, str => str.toUpperCase())}
                        </TableCell>
                        <TableCell className={classes.valueTableCell}>
                          {isEditing ? (
                            <TextField
                              name={key}
                              value={editableData[key] || ""}
                              onChange={handleChange}
                              fullWidth
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.keyTableCell}>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  {Object.entries(sections.MotherDetails).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.keyTableCell}
                        >
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, str => str.toUpperCase())}
                        </TableCell>
                        <TableCell className={classes.valueTableCell}>
                          {isEditing ? (
                            <TextField
                              name={key}
                              value={editableData[key] || ""}
                              onChange={handleChange}
                              fullWidth
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.keyTableCell}>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  {Object.entries(sections.Address).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.keyTableCell}
                      >
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, str => str.toUpperCase())}
                      </TableCell>
                      <TableCell className={classes.valueTableCell}>
                        {isEditing ? (
                          <TextField
                            name={key}
                            value={editableData.address[key] || ""}
                            onChange={handleChange}
                            fullWidth
                          />
                        ) : (
                          value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )
    );
  };

  return (
    Object.keys(stuFeeData).length !== 0 && (
      <Grid container style={{ marginBottom: "100px" }}>
        <Grid item xs={12}>
          <Grid container spacing={1} style={{ paddingBottom: "1px" }}>
            <Grid item xs={6} sm={9} cotainer justifyContent="center">
              {hideTitle ? null : (
                <ResultHeader
                  testDisplayName="View Profile"
                  path="/home-page"
                />
              )}
            </Grid>
            <Grid item xs={6} sm={3} cotainer justifyContent="center">
              {/* <Button
              round
              color="info"
              onClick={() => {
                setOpenPrintDialog(true);
              }}
            >
              Print Student Details
            </Button> */}

              <Button
                round
                color="info"
                align="center"
                onClick={() => {
                  const sDetails = {
                    title:
                      logoURLMap[store.get("user").institute] &&
                      logoURLMap[store.get("user").institute].title,
                    subTitle:
                      logoURLMap[store.get("user").institute] &&
                      logoURLMap[store.get("user").institute].subTitle,
                    logo:
                      logoURLMap[store.get("user").institute] &&
                      logoURLMap[store.get("user").institute].url,
                    ...data
                  };
             
                  const payload = {
                    phase: "enquiry",
                    docType: "enquiry",
                    data: JSON.stringify({
                      ...sDetails
                    }),
                    sid: userDetail._id
                  };
                  get(payload);
                  return () => resetTc();
                }}
              >
                Download Profile
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {openPrintDialog && (
            <PrintEnquiryProfile
              openPrintDialog={openPrintDialog}
              handleClose={handleClose}
              formatAddress={formatAddress}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <ReusableAccordion
            renderSummary={renderStudentSummary}
            renderDetails={renderStudentDetails}
            item="student"
            index={0}
            count={1}
          />
        </Grid>
        <Grid item xs={12}>
          <ReusableAccordion
            renderSummary={renderParentSummary}
            renderDetails={renderParentDetails}
            item="parent"
            index={1}
            count={count}
          />
        </Grid>
      </Grid>
    )
  );
}
