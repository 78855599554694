import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../assets/v3/base/colors";
import icici from "../../assets/svgs/icici.svg";
import CCAvenueLogo from "../../assets/img/paymentGateway/ccavenue_logo.png";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.dark.main,
    textAlign: "center",
    textTransform: "none",
    fontWeight: 600,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tbody: {
    color: colors.dark.main,
  },
  td: {
    padding: "0.125em",
    fontSize: "1rem",
  },
  tfoot: {
    borderTop: "1px solid #b4b4b4",
    fontWeight: 600,
    color: colors.dark.main,
  },
  payNowButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  paymentOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `1px solid ${colors.light.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  iconContainer: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  paymentImage: {
    backgroundColor: colors.dark.main,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  paymentDescription: {
    marginLeft: theme.spacing(4),
    color: colors.dark.main,
  },
  payNowButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  checkbox: {
    color: colors.dark.main,
    "&.Mui-checked": {
      color: colors.dark.main,
    },
  },
  tfootNoBorder: {
    fontWeight: 600,
    color: colors.dark.main,
  },
}));

const paymentGateways = [
  {
    id: "icici",
    name: "ICICI",
    logo: icici,
    description: "ICICI is a secure and fast payment gateway.",
  },
];

const CheckoutTable = ({
  fee_title,
  amounts,
  totalAmount,
  handlePayClick,
  btnClass,
  onlyTotal = "true",
  hidePaymentServices = false,
}) => {
  const [paymentGateWay, setPaymentGateWay] = useState("icici");
  const classes = useStyles();
  const hide = useMemo(() => {
    if (hidePaymentServices) {
      return true;
    }
    if (paymentGateways.length === 1) {
      return true;
    }
    return false;
  }, [hidePaymentServices]);

  if (!amounts || amounts.length === 0) return null;
  return (
    <Box mt={2} width="100%">
      <Typography variant="h5" className={classes.title}>
        {fee_title}
      </Typography>
      <br />
      {onlyTotal ? (
        <table className={classes.table}>
          <tfoot className={classes.tfootNoBorder}>
            <tr>
              <td className={classes.td}>Amount</td>
              <td className={classes.td} align="right">
                ₹{totalAmount}
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <table className={classes.table}>
          <tbody className={classes.tbody}>
            {amounts.map((item, index) => (
              <tr key={index}>
                <td className={classes.td}>{item.title}</td>
                <td className={classes.td} align="right">
                  ₹{item.value}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className={classes.tfoot}>
            <tr>
              <td className={classes.td}>Total</td>
              <td className={classes.td} align="right">
                ₹{totalAmount}
              </td>
            </tr>
          </tfoot>
        </table>
      )}

      {!hide && (
        <>
          <br />
          <Typography variant="h6" align="center" className={classes.title}>
            Select Payment Service
          </Typography>
          <br />
          {paymentGateways.map((gateway) => (
            <Box key={gateway.id} className={classes.paymentOption}>
              <Box display="flex" alignItems="center">
                <Box className={classes.iconContainer}>
                  <img src={gateway.logo} alt={gateway.name} height="30" />
                </Box>
                <Typography variant="body2" style={{ marginLeft: 8 }}>
                  {gateway.name}
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={paymentGateWay === gateway.id}
                    onChange={() => {
                      if (paymentGateWay === gateway.id) {
                        setPaymentGateWay(null);
                      } else {
                        setPaymentGateWay(gateway.id);
                      }
                    }}
                    className={classes.checkbox}
                  />
                }
              />
            </Box>
          ))}
          {paymentGateways.map(
            (gateway) =>
              paymentGateWay === gateway.id && (
                <Typography
                  key={gateway.id}
                  variant="body2"
                  className={classes.paymentDescription}
                >
                  {gateway.description}
                </Typography>
              )
          )}
        </>
      )}
      <br />
      <Box className={classes.payNowButtonContainer}>
        <Button
          className={btnClass ? btnClass : null}
          variant="contained"
          color="primary"
          onClick={() => handlePayClick(paymentGateWay)}
          size="large"
        >
          Pay Now
        </Button>
      </Box>
    </Box>
  );
};

export default CheckoutTable;
